import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import styles from "./RotationStepper.module.scss";
import {Typography} from "@mui/material";
import {updateNitrogenLevel, updateSowingRule, selectNitrogenLevel} from "../features/rotationSlice";
import CheckboxList from "../ra_components/CheckboxList";
import {
  selectSowingRules,
  fetchSowingRules,
  getSowingRulesStatus,
  getSowingRulesError} from "../features/sowingRulesSlice";
import miscHelper from "../ra_Helpers/miscHelper";
import {convertSowingRulesToList} from "../functions";

const nitrogenApplied = miscHelper.nitrogenApplied();

const ManagementStep = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();
  const sowRuleStatus = useSelector(getSowingRulesStatus);
  const sowRuleError = useSelector(getSowingRulesError);
  const sowingRules = useSelector(selectSowingRules);

  const sowingRulesList = useMemo(() => {
    const asAList = convertSowingRulesToList(sowingRules);
    return asAList;
  }, [sowingRules]);

  useEffect(() => {
    if (effectDone.current === true) {
      if (sowRuleStatus === 'idle') {
        dispatch(fetchSowingRules());
      }
    }

    return () => {
      effectDone.current = true;
    }
  }, [dispatch, sowRuleStatus]);

  const handleNitroSelect = (nApplied) => {
    if (nApplied.length > 0) {
      dispatch(updateNitrogenLevel(nApplied[0]));
    }
  };

  const handleSowingRuleSelect = (nSowing) => {
    if (nSowing.length > 0) {
      const ruleId = Number(nSowing[0]);
      if (ruleId) {
        dispatch(updateSowingRule(ruleId));
      }
    }
  };

  return (
    <div>
      <div className={styles.selectNitrogen}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            A. Select a rule when crops are sown within the planting window:
          </Typography>
        </div>
        <div className={styles.nitrogenContainer}>
          <CheckboxList
            Options={sowingRulesList}
            Columns={1}
            Title=""
            TitleBackground="#dfdfdf"
            OnSelect={handleSowingRuleSelect}
            Selected={[props.CurrentSowingRule]}
          />
        </div>
      </div>

      <div className={styles.selectNitrogen}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            B. Select the quantity of nitrogen applied to each crop:
          </Typography>
        </div>
        <div className={styles.nitrogenContainer}>
          <CheckboxList
            Options={nitrogenApplied}
            Columns={1}
            Title=""
            TitleBackground="#dfdfdf"
            OnSelect={handleNitroSelect}
            Selected={[props.CurrentNitrogenLevel]}
          />
        </div>
      </div>
    </div>
  );
}

ManagementStep.propTypes = {
  CurrentSowingRule: PropTypes.string.isRequired,
  CurrentNitrogenLevel: PropTypes.string.isRequired,
}

export default ManagementStep;
