import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid, Typography} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import BoxChart2 from "../ra_components/BoxChart2";
import PlantingsTable from "../ra_components/PlantingsTable";
import LineChart from "../ra_components/LineChart";
import CDFChart from "../ra_components/CDFChart";
import ParallelChart from "../ra_components/ParallelChart";
import {anycaseEquals, ColourLuminance, makeAColour, SummariseDataByIndicator} from "../functions";
import LoadingComponent from "../ra_components/LoadingComponent";
import CompareRotationLevel from "./CompareRotationLevel";
import {selectWaterBalance} from "../features/sitesSlice";
import {selectCrops, selectCropLevelIndicators} from "../features/rotationSlice";
import miscHelper from "../ra_Helpers/miscHelper";
import LineChart2 from "../ra_components/LineChart2";

const seasonalCond = miscHelper.seasonalClimateConditions();

const CompareCropLevel = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();

  const [refLineChartTitle, setRefLineChartTitle] = useState('');
  const [altLineChartTitle, setAltLineChartTitle] = useState('');
  const [refLineChartData, setRefLineChartData] = useState(null);
  const [altLineChartData, setAltLineChartData] = useState(null);
  const [boxPlotResults, setBoxPlotResults] = useState(null);

  const allIndicators = useSelector(selectCropLevelIndicators);
  const crops = useSelector(selectCrops);
  const waterBalance = useSelector(selectWaterBalance);

  //region Memoised variables
  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const currentIndicatorDesc = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        setRefLineChartTitle(`Annual results (Reference): ${indicatorObj.display}`);
        setAltLineChartTitle(`Annual results (Alternate): ${indicatorObj.display}`);
        return indicatorObj.display;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const currentIndicatorObj = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj;
      } else {
        return null;
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const currentUnits = useMemo(() => {
    if (props.CurrentIndicator) {
      const indicatorObj = allIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj.units;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator, allIndicators]);

  const parallelIndicators = useMemo(() => {
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);
    return filteredIndicators;
  }, [allIndicators]);

  const selectedWaterBalance = useMemo(() => {
    let results = [];
    if (props.CurrentConditions) {
      results = waterBalance.filter(wb => props.CurrentConditions.includes(wb.condition));
    }
    return results;
  }, [props.CurrentConditions, waterBalance]);

  const foundCrops = useMemo(() => {
    const found = {};
    if (props.CompareData) {
      if (props.CompareData?.rotations.length > 0) {
        props.CompareData.rotations.forEach(r => {
          const rotationType = r.Type;
          let cropSet = new Set();
          Object.keys(r).forEach(key => {
            if (key.includes('Summer') || key.includes('Winter')) {
              if (r[key] !== 'fallow') {
                cropSet.add(r[key]);
              }
            }
          });

          found[rotationType] = Array.from(cropSet);
        });
      }
    }

    return found;
  }, [props.CompareData]);
  //endregion

  useEffect(() => {
    // console.log('CompareCropLevel useEffect', props.CompareData);

    if (props.CompareData) {
      // console.log('Current Conditions', currentConditions);
      const {cult_seasonal} = props.CompareData;
      if (cult_seasonal.length > 0) {
        // All seasons data.
        const altData = cult_seasonal.filter(d => d.BaseRotationId === props.AltRotationId);
        const refData = cult_seasonal.filter(d => d.BaseRotationId === props.RefRotationId);

        // Seasonal data.
        const altSeasonalData = altData.filter(d => props.CurrentConditions.includes(d.condition));
        const refSeasonalData = refData.filter(d => props.CurrentConditions.includes(d.condition));

        // Fallow ignored by default.
        const altAllSeasonSumm = SummariseDataByIndicator(altData, allIndicators, props.Crops);
        const refAllSeasonSumm = SummariseDataByIndicator(refData, allIndicators, props.Crops);
        const altSeasonalSumm = SummariseDataByIndicator(altSeasonalData, allIndicators, props.Crops);
        const refSeasonalSumm = SummariseDataByIndicator(refSeasonalData, allIndicators, props.Crops);

        //region Setup for Box plots
        const boxPlotData = {
          AllSeasons: {
            name: 'All Seasons',
            alternate: altAllSeasonSumm,
            reference: refAllSeasonSumm,
          },
          SelectSeasons: {
            name: 'Seasonal Seasons',
            alternate: altSeasonalSumm,
            reference: refSeasonalSumm,
          },
        };

        setBoxPlotResults(boxPlotData);
        //endregion

        //region Setup for Line charts
        if (props.CompareData.RefRotationId && foundCrops.reference) {
          const refLineData = formatLineChartData(cult_seasonal, props.CompareData.RefRotationId, foundCrops.reference);
          setRefLineChartData(refLineData);
          // console.log('Reference Line Chart', refLineData);
        }
        if (props.CompareData.AltRotationId && foundCrops.alternate) {
          const altLineData = formatLineChartData(cult_seasonal, props.CompareData.AltRotationId, foundCrops.alternate);
          setAltLineChartData(altLineData);
          // console.log('Alternate Line Chart', altLineData);
        }

        // Need to ensure both line charts use the same y axis range.

        //endregion
      }
    }
  }, [dispatch, props.CompareData, props.CurrentConditions, foundCrops]);

  const getCropColour = (cropname, withGradient = true) => {
    let result = null;

    const lcCropName = cropname.toString().trim().toLowerCase();
    const aCrop = crops.find(c => c.Name.toLowerCase() === lcCropName);

    if (aCrop) {
      const startColour = makeAColour(aCrop.bg_colour);

      if (withGradient) {
        let stopColour = ColourLuminance(startColour, 0.2);
        if (aCrop.neverDarken === 1) {
          stopColour = ColourLuminance(startColour, 1.2);
        }
        const perShapeGradient = {x1: 0, y1: 0, x2: 1, y2: 1};
        const gradient = {
          linearGradient: perShapeGradient,
          stops: [[0, startColour], [1, stopColour]],
        };

        result = gradient;
      } else {
        result = makeAColour(aCrop.bg_colour);
      }
    }

    return result;
  };

  const formatLineChartData = (data, rotationId, foundCrops) => {
    // Using props.CompareData.cult_seasonal
    let results = [];
    let indicatorFieldName = currentIndicatorObj.value;
    let min = null;
    let max = null;

    if (currentIndicatorObj) {
      if (props.CompareData.hasOwnProperty('cult_seasonal')) {
        foundCrops.forEach(crop => {
          const cropSolidColour = getCropColour(crop, false);
          const cropDataObj = {
            name: crop,
            color: cropSolidColour,
          };

          const cropData = data.filter(d => d.BaseRotationId === rotationId && d.crop === crop);
          cropData.sort((a, b) => a.year - b.year);
          cropDataObj.data = cropData.map(c => {
            const value = c[indicatorFieldName];
            if (min == null || value < min) {
              min = value;
            }
            if (max == null || value > max) {
              max = value;
            }
            const point = {
              x: c.year,
              y: c[indicatorFieldName]
            };

            return point;
          });

          cropDataObj.min = min;
          cropDataObj.max = max;

          results.push(cropDataObj);
        });
      }
    }

    return results;
  };

  const handleIndicatorChange = (index, new_indicator) => {
    // console.log('CompareCropLevel handleIndicatorChange');
    // In this instance the index can be ignored.
    if (new_indicator) {
      if (new_indicator.length > 0) {
        props.OnIndicatorChange(new_indicator);
      }
    }
  };

  const handleSeasonalConditionsChange = (conditions) => {
    console.log('got conditions', conditions);
    props.OnConditionsChange(conditions);
  };

  //region Determine the overall min and max.
  let overallMin = null;
  let overallMax = null;
  let chartMin = null;
  let chartMax = null;

  if (refLineChartData) {
    refLineChartData.forEach(d => {
      if (overallMin == null || d.min < overallMin) {
        overallMin = d.min;
      }
      if (overallMax == null || d.max > overallMax) {
        overallMax = d.max;
      }
    });
  }

  if (altLineChartData) {
    altLineChartData.forEach(d => {
      if (overallMin == null || d.min < overallMin) {
        overallMin = d.min;
      }
      if (overallMax == null || d.max > overallMax) {
        overallMax = d.max;
      }
    });
  }

  if (overallMin && overallMax) {
    chartMin = Math.ceil(Math.abs(overallMin) / 100) * 100;
    if (overallMin < 0) {
      chartMin = chartMin * -1;
    }
    chartMax = Math.ceil(Math.abs(overallMax) / 100) * 100;
    if (overallMax < 0) {
      chartMax = chartMax * -1;
    }
  }
  //endregion

  const linechartKey1 = Date.now();
  const linechartKey2 = Date.now();

  // console.log(boxPlotResults);

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
          <div>
            <ResultIndicatorModal
              CurrentIndicator={props.CurrentIndicator}
              CurrentIndicatorDesc={currentIndicatorDesc}
              SelectableIndicators={selectableIndicators}
              OnResultIndicatorChange={handleIndicatorChange}
              Label='Result Indicator:'
              LabelBefore={false}
              WithBorder={false}
            />
          </div>
          <div style={{marginLeft: '10px'}}>
            <SeasonalCondModal
              OnSeasonalConditionsChange={handleSeasonalConditionsChange}
              Label="Selected Seasons:"
              SeasonalCondList={seasonalCond}
              SelectedConditions={props.CurrentConditions} />
          </div>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="boxplotcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_box_plot" Position='auto' AnchorTo="boxplotcontainer">
                <BoxChart2
                  BoxPlotData={boxPlotResults}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  SeasonalConditions={props.CurrentConditions}
                  Crops={crops}
                  Units={currentUnits}
                  InfoKey="box_plot"
                />
              </InfoButton>
            </div>
          </Grid>
          <Grid item xs={6}>
            {refLineChartData && <div id="linechartcontainer1" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer1">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={refLineChartData}
                  Title={refLineChartTitle}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  key={linechartKey1}
                  YAxisMin={chartMin}
                  YAxisMax={chartMax}
                />
              </InfoButton>
            </div>}
          </Grid>
          <Grid item xs={6}>
            {altLineChartData && <div id="linechartcontainer2" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer2">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={altLineChartData}
                  Title={altLineChartTitle}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  key={linechartKey2}
                  YAxisMin={chartMin}
                  YAxisMax={chartMax}
                />
              </InfoButton>
            </div>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CompareCropLevel.propTypes = {
  CompareData: PropTypes.object.isRequired,
  CurrentIndicator: PropTypes.string.isRequired,
  CurrentConditions: PropTypes.array.isRequired,
  AltRotationId: PropTypes.number.isRequired,
  RefRotationId: PropTypes.number.isRequired,
  OnIndicatorChange: PropTypes.func,
  OnConditionsChange: PropTypes.func,
};

CompareCropLevel.defaultProps = {
  OnIndicatorChange: () => {},
  OnConditionsChange: () => {},
}

export default CompareCropLevel;
