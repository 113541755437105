import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import styles from './sharedPageStyles.module.scss';
import {capitalise, CDFValues, ColourDarken, ColourLuminance, getOverallMinMax, makeAColour} from "../functions";
import InfoIcon from "../ra_images/infoIcon.png";
import LineChart from "./LineChart";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const CDFChart2 = (props) => {
  const [chartMinData, setChartMinData] = useState(null);
  const [chartMaxData, setChartMaxData] = useState(null);

  const getXAxisTitle = () => {
    let result = '';
    if (props.XAxisTitle === '') {
      result = capitalise(props.ResultIndicator);
    } else {
      result = props.XAxisTitle;
    }

    if (props.Units !== '') {
      result += ` (${props.Units})`;
    }

    return result;
  };

  const getYAxisTitle = () => {
    return (props.YAxisTitle === '') ? 'Percentile' : props.YAxisTitle;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Cumulative Distribution: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  // const minmaxData = getOverallMinMax(data);

  const getChartOptions = () => {
    return {
      chart : {
        zoomType : 'x',
      },
      credits: {
        enabled: false
      },
      title : {
        text : getTitle()
      },
      legend : {
        enabled : true
      },
      xAxis : {
        maxZoom : 200,
        enabled: true,
        title: {
          text: getXAxisTitle(),
        }
      },
      yAxis : {
        floor: 0,
        ceiling: 1,
        title : {
          text : getYAxisTitle(),
        }
      },
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.y:.2f} %</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.x:.1f} ' + props.Units + '</b></td></tr>',
        footerFormat : '</table>',
        useHTML : true,
        valueDecimals : 2
      },
      plotOptions: {
        line: {
          step: 'left',
        }
      },
      series: props.Series,
    }
  };

  return (
    <div className={styles.graphContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
        constructorType={ 'chart' }
      />
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}


CDFChart2.propTypes = {
  Series: PropTypes.array.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  XAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

CDFChart2.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  XAxisTitle: '',
  InfoKey: '',
};

export default CDFChart2;
