import {createAsyncThunk, createSlice, createSelector} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";
import miscHelper from '../ra_Helpers/miscHelper';

const initialState = {
  rotations: [],
  crops: [],
  // User selected values.
  selectedCrops: [],
  baseRotationId:  null,
  nitrogenLevel: '',
  sowingRuleId: 0,
  seasonalConditions: [],   // User selected seasonal conditions
  // Section B data. Crop Level Indicators
  resultIndicators: miscHelper.resultIndicators(),
  resultIndicator: 'yield',
  resultIndicatorDesc: '',
  resultIndicatorObj: null,
  // Section C data. Rotation Level data.
  sectionCIndicators: miscHelper.sectionCIndicators(),
  sectionCIndicator: 'gross_margin',
  sectionCIndicatorObj: null,
  status: 'idle',
  error: null,
}

export const fetchRotations = createAsyncThunk('rotation/fetchRotations', async (arg, {getState}) => {
  try {
    const url = `/rotations`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchCrops = createAsyncThunk('rotation/fetchCrops', async (arg, {getState}) => {
  try {
    const url = `/crops`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const rotationSlice = createSlice({
  name: 'rotation',
  initialState,
  reducers: {
    updateSelectedCrops: (state, action) => {
      // Payload is expected to be an array of crop names.
      state.selectedCrops = [...action.payload];
    },
    clearSelectedCrops: (state) => {
      state.selectedCrops = [];
    },
    updateBaseRotation: (state, action) => {
      // Payload is expected to be a rotation id.
      state.baseRotationId = action.payload;
    },
    updateNitrogenLevel: (state, action) => {
      // Payload is expected to be a nitrogen level string.
      state.nitrogenLevel = action.payload;
    },
    updateSowingRule: (state, action) => {
      // Payload is expected to be a sowing rule string.
      state.sowingRuleId = action.payload;
    },
    replaceRotation: (state, action) => {
      const {RotationId, SowingRuleId, NLevel} = action.payload;
      state.baseRotationId = Number(RotationId);
      state.sowingRuleId = SowingRuleId;
      state.nitrogenLevel = NLevel;
    },
    updateSeasonalConditions: (state, action) => {
      // Payload is expected to be a seasonal conditions array.
      state.seasonalConditions = [...action.payload];
    },
    updateResultIndicator: (state, action) => {
      // Payload is expected to be a result indicator string.
      const indicator = action.payload.toString().trim().toLowerCase();
      state.resultIndicator = indicator;

      const resultObj = state.resultIndicators.find(i => i.value === indicator);
      if (resultObj) {
        let desc = resultObj.display;
        if (resultObj.details !== '') {
          desc += ` (${resultObj.details})`;
        }
        state.resultIndicatorDesc = desc;
        state.resultIndicatorObj = resultObj;
      }
    },
    updateSectionCIndicator: (state, action) => {
      // Payload is expected to be a result indicator string.
      const indicator = action.payload.toString().trim().toLowerCase();
      state.sectionCIndicator = indicator;

      const resultObj = state.sectionCIndicators.find(i => i.value === indicator);
      if (resultObj) {
        state.sectionCIndicatorObj = resultObj;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRotations.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchRotations.fulfilled, (state, action) => {
        state.status = 'success';

        const {rotations} = action.payload;
        state.rotations = [...rotations];
      })
      .addCase(fetchRotations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCrops.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchCrops.fulfilled, (state, action) => {
        state.status = 'success';

        const {crops} = action.payload;
        state.crops = crops;
      })
      .addCase(fetchCrops.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});


export const {updateSelectedCrops, clearSelectedCrops, updateBaseRotation} = rotationSlice.actions;
export const {updateNitrogenLevel, updateSowingRule} = rotationSlice.actions;
export const {updateSeasonalConditions, updateResultIndicator} = rotationSlice.actions;
export const {updateSectionCIndicator, replaceRotation} = rotationSlice.actions;

export const selectCrops = (state) => state.rotation.crops;
export const selectSelectedCrops = (state) => state.rotation.selectedCrops;
export const selectNonFallowCrops = (state) => state.rotation.crops.filter(f => f.isFallow !== 1);

export const selectRotations = (state) => state.rotation.rotations;
export const selectBaseRotation = (state) => state.rotation.baseRotationId;
export const selectBaseRotationObj = (state) => {
  if (state.rotation.rotations) {
    if (state.rotation.baseRotationId !== null) {
      const rotObj = state.rotation.rotations.find(
        r => r.id === state.rotation.baseRotationId
      );
      if (rotObj) {
        return rotObj;
      }
    }
  }

  return null;
};

export const selectNitrogenLevel = (state) => state.rotation.nitrogenLevel;
export const selectSowingRuleId = (state) => state.rotation.sowingRuleId;
export const selectSeasonalConditions = (state) => state.rotation.seasonalConditions;

// Memoised Selector
const getRotationId = (state) => state.rotation.baseRotationId;
const getNLevel = (state) => state.rotation.nitrogenLevel;
const getSowingRuleId = (state) => state.rotation.sowingRuleId;

export const getReferenceRotation = createSelector(
  [getRotationId, getNLevel, getSowingRuleId],
  (rotationid, nlevel, sowingruleid) => {
    const refRotation = {
      Type: 'reference',
      RotationId: rotationid,
      NLevel: nlevel,
      SowingRuleId: sowingruleid,
    };
    return refRotation;
  }
);

// export const selectReferenceRotation = (state) =>  {
//   const refRotation = {
//     Type: 'reference',
//     RotationId: state.rotation.baseRotationId,
//     NLevel: state.rotation.nitrogenLevel,
//     SowingRuleId: state.rotation.sowingRuleId,
//   };
//
//   return refRotation;
// };

// Override selector names with more meaningful names. Use these in the future.
export const selectRotationLevelIndicators = (state) => state.rotation.sectionCIndicators;
export const selectRotationLevelIndicator = (state) => state.rotation.sectionCIndicator;
export const selectRotationLevelIndicatorObj = (state) => {
  if (state.rotation.sectionCIndicators) {
    if (state.rotation.sectionCIndicator !== '') {
      const indObj = state.rotation.sectionCIndicators.find(
        r => r.value === state.rotation.sectionCIndicator
      );
      if (indObj) {
        return indObj;
      }
    }
  }

  return null;
};

export const selectCropLevelIndicators = (state) => state.rotation.resultIndicators;
export const selectCropLevelIndicator = (state) => state.rotation.resultIndicator;
export const selectCropLevelIndicatorDesc = (state) => state.rotation.resultIndicatorDesc;
export const selectCropLevelIndicatorObj = (state) => {
  if (state.rotation.resultIndicators) {
    if (state.rotation.resultIndicator !== '') {
      const indObj = state.rotation.resultIndicators.find(
        r => r.value === state.rotation.resultIndicator
      );
      if (indObj) {
        return indObj;
      }
    }
  }

  return null;
};

//region Discontinue the use of these selectors. (some may still be in use)
export const selectResultIndicators = (state) => state.rotation.resultIndicators;
export const selectResultIndicator = (state) => state.rotation.resultIndicator;
export const selectSectionCIndicators = (state) => state.rotation.sectionCIndicators;
export const selectSectionCIndicator = (state) => state.rotation.sectionCIndicator;
export const selectResultIndicatorDesc = (state) => state.rotation.resultIndicatorDesc;
export const selectResultIndicatorObj = (state) => {
  if (state.rotation.resultIndicators) {
    if (state.rotation.resultIndicator !== '') {
      const indObj = state.rotation.resultIndicators.find(
        r => r.value === state.rotation.resultIndicator
      );
      if (indObj) {
        return indObj;
      }
    }
  }

  return null;
};
export const selectSectionCIndicatorObj = (state) => {
  if (state.rotation.sectionCIndicators) {
    if (state.rotation.sectionCIndicator !== '') {
      const indObj = state.rotation.sectionCIndicators.find(
        r => r.value === state.rotation.sectionCIndicator
      );
      if (indObj) {
        return indObj;
      }
    }
  }

  return null;
};
//endregion

export const getRotationStatus = (state) => state.rotation.status;
export const getRotationError = (state) => state.rotation.error;

export default rotationSlice.reducer;
