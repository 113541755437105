import React, { Component } from 'react';
import sharedStyles from './sharedPageStyles.module.scss';
import pageStyles from './MainPage.module.scss';

import { Button, Typography, Container, Box, iconButtonClasses } from '@mui/material';

import ScenarioContainer from './ScenarioContainer';

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideDescription: false, // boolean
            hideStepper: true, // boolean
        };
    }

    componentDidMount() {
        // Google Analytics
        // ReactGA.event({
        //     category: 'navigation',
        //     action: 'hit',
        //     label: 'CropARM Page',
        // });
    }

    handleGetStarted = (event) => {
        this.setState({
            hideDescription: true,
            hideStepper: false,
        })
    }

    render() {
        return (
            <Container maxWidth="lg">
                <Box sx={{ width: '100%' }}>
                    <div className={sharedStyles.descriptionArea}>
                        <div className={sharedStyles.headerSection}>
                            <Typography variant="h4">
                                CropARM - Crop Analysis for Risk Management
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                <i>(formerly WhopperCropper)</i>
                            </Typography>
                        </div>
                        <div hidden={this.state.hideDescription}>
                            <Typography variant="body1" gutterBottom>
                                Farmers in all regions of Australia endure widely varying rainfall conditions whilst needing to make critical management decisions prior to every cropping season. Each input option comes with a possible range of effects on outputs (especially yield) and can have interactions with other inputs.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                CropARM is a simple software tool that facilitates simulation-aided discussion of growers’ exposure to risk when comparing different management options. The simulations utilise the 115-year climate record to predict the year-to-year variability in outcomes. The effect of different levels of inputs can be compared side-by-side. The management factors that can be examined include:
                            </Typography>
                            {/* Material UI does have a <List> element, but that's a bit overkill for the below. */}
                            <ul className={sharedStyles.itemList}>
                                <li>Crop type</li>
                                <li>Effect of stored soil water at planting</li>
                                <li>Sowing date</li>
                                <li>Maturity length</li>
                                <li>Plant population</li>
                                <li>Row configuration</li>
                                <li>Effect of soil nitrogen content</li>
                                <li>Nitrogen fertiliser rate (sow and in-crop)</li>
                            </ul>
                            <Typography variant="body1" gutterBottom>
                                Each factor contains 3 to 6 levels from which to choose. These levels can constitute the scenario comparison or any number of levels (acts like a input ‘rate’ trial) or these factors can be combined with multiple levels within any other factor (similar to a ‘factorial’ experiment).
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                CropARM has approximately 20 output options, including crop yield, water use, days to harvest as well as temperature stress indices (e.g. frost/low temperatures around flowering). It also includes a simple gross margin calculator.
                            </Typography>
                        </div>
                        <ScenarioContainer hideBuilder={this.state.hideStepper}></ScenarioContainer>
                        <div className={pageStyles.buttonContainer} hidden={this.state.hideDescription}>
                            <Button
                              id="btn_started_wc"
                              variant="contained"
                              size="large"
                              id="tool_start_CropARM"
                              onClick={this.handleGetStarted}>
                                Get Started
                            </Button>
                        </div>
                    </div>
                </Box>
            </Container>
        )
    }
}

export default MainPage;
