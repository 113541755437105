import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import styles from "./RotationStepper.module.scss";
import {Typography} from "@mui/material";
// import SitePicker from "../ra_components/SitePicker";
import SitePicker2 from '../ra_components/SitePicker2';
import CheckboxList from "../ra_components/CheckboxList";
import {
  resetPAWCs, selectSites,
  selectPAWC, selectPAWCList,
  selectSite, selectSiteDetail,
  updatePAWC, updateSite, fetchWaterBalance
} from "../features/sitesSlice";
import miscHelper from "../ra_Helpers/miscHelper";
import {fetchPAWCs} from "../features/sitesSlice";

const SiteStep = (props) => {
  const dispatch = useDispatch();
  const currentPAWC = useSelector(selectPAWC);
  const currentSite = useSelector(selectSite);    // This is a siteId
  const currentSiteDetail = useSelector(selectSiteDetail);
  const currentState = currentSiteDetail.state || null;
  const sites = useSelector(selectSites);
  const sitePAWCs = useSelector(selectPAWCList);

  const PAWCs = useMemo(() => {
    const PAWCary = miscHelper.PAWCOptions(sitePAWCs);

    return PAWCary;
  }, [sitePAWCs]);

  const handleStateChange = (stateValue) => {
    dispatch(resetPAWCs());
  }

  const handleSiteSelection = (site, value) => {
    // Receives: Site object, true/false (which is ignored).
    // Call API to get PAWC values for this site.
    if (site) {
      // console.log('SiteStep handleSiteSelection', site);
      if (currentSite !== site.Name) {
        dispatch(updateSite(site.id));
      }

      // Make api call to get pawc values and water balance details.
      dispatch(fetchPAWCs({siteId: site.id}));
      dispatch(fetchWaterBalance({siteId: site.id}));
    }
  };

  const handleSoilPAWCSelect = (selectedPAWC) => {
    if (selectedPAWC.length > 0) {
      const pawcVal = Number(selectedPAWC[0]);
      dispatch(updatePAWC(pawcVal));
    }
  };

  const strCurrentPAWC = currentPAWC.toString();
  // console.log('Site Step Current PAWC', strCurrentPAWC);

  return (
    <div>
      <div>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            A. Select the site to analyse:
          </Typography>
        </div>
        <SitePicker2
          CurrentSite={currentSite}
          CurrentState={currentState}
          CurrentSiteName={currentSiteDetail.name}
          HandleSiteSelection={handleSiteSelection}
          OnStateChange={handleStateChange}
          Title=""
          Sites={sites}
        />
      </div>

      {PAWCs.length > 0 && <div className={styles.selectNitrogren}>
        <div className={styles.shadedTitle}>
          <Typography variant="h6" fontWeight="bold">
            B. Select how much water your soil profile can store (i.e., the Plant Available Water Capacity):
          </Typography>
        </div>
        <div className={styles.soilprofileContainer}>
          <CheckboxList
            Selected={[strCurrentPAWC]}
            Options={PAWCs}
            Columns={1}
            Title=""
            TitleBackground="#efefef"
            OnSelect={handleSoilPAWCSelect} />
        </div>
      </div>}
    </div>
  );
}

export default SiteStep;
