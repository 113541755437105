import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";
import {ExploreListRowType, SortExploreTableData} from "../functions";

const initialState = {
  // Section B Data.
  // now called CropLevel Results
  resultIndicator_CropLevel: 'Yield',
  cropLevel_data: null,
  haveCropLevelData: false,
  status_CropLevel: 'idle',
  error_CropLevel: null,

  // Section C Data.
  // now called RotationLevel Results
  resultIndicator_RotationLevel: '',
  rotationLevel_data: null,
  haveRotationLevelData: false,
  status_RotationLevel: 'idle',
  error_RotationLevel: null,

  haveResultsData: false,
};

//region Fetch result data from API.

// expected parms = {
//  siteId:
//  pawc:
//  baseRotationId:
//  sowingRuleId:
//  nLevel:
//  condition1:
//  condition2:
// }
export const fetchCropLevelResults = createAsyncThunk(
  'results/fetchCropLevelResults',
  async ({parms}, {getState}) => {
    try {
      // console.log('resultsSlice_fetchCropLevelResults', parms);
      if (parms) {
        const url = `/scenario?siteId=${parms.siteId}&pawc=${parms.pawc}`;
        const response = await axiosRotation.post(url, parms);
        return response.data;
      }
    } catch (err) {
      return err.message;
    }
  }
);

// expected parms = {
//  siteId:
//  pawc:
//  baseRotationId:
//  indicators: [],
//  indicator:
//  sowingRuleId:
//  nLevel:
// }
export const fetchRotationLevelResults = createAsyncThunk(
  'results/fetchRotationLevelResults',
  async ({parms}, {getState}) => {
    try {
      // console.log('resultsSlice_fetchSectionC', parms);
      if (parms) {
        const url = `/sectionc?siteId=${parms.siteId}&pawc=${parms.pawc}`;
        const response = await axiosRotation.post(url, parms);
        return response.data;
      }
    } catch (err) {
      return err.message;
    }
  }
);
//endregion

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    dataReset: (state, action) => {
      const which = action.payload.toString().toLowerCase();
      if  (which.includes('b') || which === '' || which === 'all') {
        state.cropLevel_data = null;
        state.haveCropLevelData = false;
        state.status_CropLevel = 'idle';
        state.error_CropLevel = null;
      }
      if  (which.includes('c') || which === '' || which === 'all') {
        state.rotationLevel_data = null;
        state.haveRotationLevelData = false;
        state.status_RotationLevel = 'idle';
        state.error_RotationLevel = null;
      }
    },
    updateResultIndicatorCropLevel : (state, action) => {
      const ind = action.payload;
      state.resultIndicator_CropLevel = ind;
    },
    updateResultIndicatorRotationLevel : (state, action) => {
      const ind = action.payload;
      state.resultIndicator_RotationLevel = ind;
    },
    updateData: (state, action) => {
      const {data} = action.payload;

      // Modify the item with the new information.
      state.cropLevel_data = data;
      state.haveResultsData = true;
      state.haveCropLevelData = true;
    },
    updateCropLevelData: (state, action) => {
      const {data} = action.payload;

      // Modify the item with the new information.
      state.cropLevel_data = data;
      state.haveResultsData = true;
      state.haveCropLevelData = true;
    },
    updateRotationLevelData: (state, action) => {
      const {data} = action.payload;

      // Modify the item with the new information.
      state.rotationLevel_data = data;
      state.haveRotationLevelData = true;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCropLevelResults.pending, (state, action) => {
        state.status_CropLevel = 'loading';
      })
      .addCase(fetchCropLevelResults.fulfilled, (state, action) => {
        state.status_CropLevel = 'success';
        state.error_CropLevel = null;
        let dataOK = false;
        if (action.payload.hasOwnProperty('baseRotation_yearly')) {
          if (action.payload.baseRotation_yearly.length > 0) {
            dataOK = true;
          }
        }
        if (action.payload.hasOwnProperty('baseRotation_timeAgg')) {
          dataOK = true;
        } else {
          dataOK = false;
        }
        state.haveCropLevelData = dataOK;
        state.cropLevel_data = action.payload;
      })
      .addCase(fetchCropLevelResults.rejected, (state, action) => {
        state.status_CropLevel = 'failed';
        state.error_CropLevel = action.error.message;
      })
      .addCase(fetchRotationLevelResults.pending, (state, action) => {
        state.status_RotationLevel = 'loading';
      })
      .addCase(fetchRotationLevelResults.fulfilled, (state, action) => {
        state.status_RotationLevel = 'success';
        state.error_RotationLevel = null;
        // console.log('resultsSlice_RotationLevel - success', action);
        state.rotationLevel_data = action.payload;
      })
      .addCase(fetchRotationLevelResults.rejected, (state, action) => {
        state.status_RotationLevel = 'failed';
        state.error_RotationLevel = action.error.message;
      })
  },
});

export const {updateData, updateCropLevelData, updateRotationLevelData} = resultsSlice.actions;
export const {updateStatus, updateError} = resultsSlice.actions;
export const {updateResultIndicatorCropLevel, updateResultIndicatorRotationLevel} = resultsSlice.actions;

export const selectCropLevelData = (state) => state.results.cropLevel_data;
export const getHaveCropLevelData = (state) => state.results.haveCropLevelData;
export const selectResultIndicatorCropLevel = (state) => state.results.resultIndicator_CropLevel;
export const selectCropLevelStatus = (state) => state.results.status_CropLevel;
export const selectCropLevelError = (state) => state.results.error_CropLevel;

export const selectRotationLevelData = (state) => state.results.rotationLevel_data;
export const getHaveRotationLevelData = (state) => state.results.haveRotationLevelData;
export const selectResultIndicatorRotationLevel = (state) => state.results.resultIndicator_RotationLevel;
export const selectRotationLevelStatus = (state) => state.results.status_RotationLevel;
export const selectRotationLevelError = (state) => state.results.error_RotationLevel;


export default resultsSlice.reducer;
