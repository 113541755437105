import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import CropTableCell from "./CropTableCell";
import {Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {isNumeric} from "../functions";

// Expected format for each parameter object.
// Planted: {
//    AllSeasons: [{cropname, value},],
//    Seasonal: [{cropname, value}, ],
// }
// Missed: {
//    AllSeasons: [{cropname, value},],
//    Seasonal: [{cropname, value}, ],
// }

//region Setup different table styles
const sxNoBottom = {
  borderBottomWidth: 0,
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxContainer = {
  // width: "max-content",
  width: 'auto',
  marginLeft: "auto",
  marginRight: "auto",
  padding: '2px',
  paddingBottom: '5px',
  paddingTop: '5px',
};
//endregion

const PlantingsTable = (props) => {
  const effectDone = useRef(false);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (effectDone.current === true) {
    }

    const allRows = [];
    if (props.Planted && props.Missed) {
      // Build row for crops planted.
      let rowArray = [];
      props.Planted.AllSeasons.forEach(p => {
        rowArray.push(p.value);
      });
      rowArray.push('Crops Planted');
      props.Planted.Seasonal.forEach(p => {
        rowArray.push(p.value);
      });
      allRows.push([...rowArray]);

      // Build row for crops missed.
      rowArray = [];
      props.Missed.AllSeasons.forEach(m => {
        rowArray.push(m.value);
      });
      rowArray.push('Crops Missed');
      props.Missed.Seasonal.forEach(m => {
        rowArray.push(m.value);
      });
      allRows.push([...rowArray]);
    }
    setRows(allRows);

    return () => {
      effectDone.current = true;
    }
  }, []);

  const renderHeadingCrops = (obj, indexOffset) => {
      return obj.map((o, index) =>
        <CropTableCell
          TableRowIndex={0}
          CropName={o.cropname}
          TableColumnIndex={indexOffset + index}
          RightBorder={true}
          LeftBorder={index === 0}
          Width="auto"
          key={`H_${o.cropname}_${indexOffset}`}
        />
      );
  };

  const renderRow = (rowArray, rowIndex) => {
    return rowArray.map((rowItem, index) => {
      let cellValue = rowItem;
      const cellKey = `PROW${rowIndex}CELL${index}`;
      if (isNumeric(rowItem)) {
        cellValue = Number(rowItem).toString() + '%';
      }
      return (<TableCell key={cellKey} align="center">
                <strong>{cellValue}</strong>
              </TableCell>);
    });
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{...sxContainer}}>
        {props.Title !== '' && <Typography variant="h5" align="center" fontWeight="bold">{props.Title}</Typography>}
        <Table sx={{width: "80%",
          "&.MuiTable-root": {
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#000000",
            borderSpacing: 1,
          }
        }}
        size="small"
        align="center"
        aria-label="rotation list">
          <TableHead sx={{top: 0, zIndex: 1, "& .MuiTableCell-root": { backgroundColor: "background.paper" }, }}>
            <TableRow key="header1" >
              {renderHeadingCrops(props.Planted.AllSeasons, 0)}
              <TableCell align="center" sx={{...sxNoBottom}} key="PROW_c">{'  '}</TableCell>
              {renderHeadingCrops(props.Planted.Seasonal, props.Planted.AllSeasons.length + 1)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => {
              // const rowKey = `ROW${rowIndex + 2}`;
              const rowKey = `PROW${rowIndex}`;
              return (
                <TableRow hover key={rowKey} >
                  {renderRow(row, rowIndex + 1)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

PlantingsTable.propTypes = {
  Planted: PropTypes.object.isRequired,
  Missed: PropTypes.object.isRequired,
  Title: PropTypes.string,
};

PlantingsTable.defaultProps = {
  Title: 'Planting Frequency',
};

export default PlantingsTable;
