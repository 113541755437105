import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";

const initialState = {
  sowingRules: [],
  status: 'idle',
  error: null,
}

export const fetchSowingRules = createAsyncThunk('sowingRules/fetchSowingRules', async (arg, {getState}) => {
  try {
    const url = `/sowingrules`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const sowingRulesSlice = createSlice({
  name: 'sowingRules',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSowingRules.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchSowingRules.fulfilled, (state, action) => {
        state.status = 'success';

        const sowingRules = action.payload.sowingRules;
        state.sowingRules = sowingRules;
      })
      .addCase(fetchSowingRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export const selectSowingRules = (state) => state.sowingRules.sowingRules;
export const getSowingRulesStatus = (state) => state.sowingRules.status;
export const getSowingRulesError = (state) => state.sowingRules.error;

export const selectSowingRuleById = (state, ruleId) =>
  state.sowingRules.sowingRules.find(sr => sr.id === ruleId);

export default sowingRulesSlice.reducer;
