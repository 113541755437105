import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import {store} from './app/store';
import {Provider} from 'react-redux';
import {fetchSites} from "./features/sitesSlice";
import {fetchInfoItems} from "./features/infoDataSlice";
import {fetchRotations} from "./features/rotationSlice";
import {fetchCrops} from "./features/rotationSlice";
import {fetchSowingRules} from "./features/sowingRulesSlice";
import {fetchAllCommodities, fetchCropARMSites} from "./features/croparmSlice";
import {useWhatChanged, setUseWhatChange} from "@simbathesailor/use-what-changed";

store.dispatch(fetchSites());
store.dispatch(fetchInfoItems());
store.dispatch(fetchRotations());
store.dispatch(fetchCrops());
store.dispatch(fetchSowingRules());
store.dispatch(fetchCropARMSites());
store.dispatch(fetchAllCommodities());

setUseWhatChange(process.env.NODE_ENV === 'development')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store} >
        <App />
      </Provider>
    </BrowserRouter>
  // </React.StrictMode>
);
