import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import pageStyles from "../ra_containers/ExploreStep.module.scss";
import Typography from "@mui/material/Typography";
import {List, ListItem} from "@mui/material";

const ExploreQuestions = (props) => {
  return (
    <div className={pageStyles.exploreContainer}>
      <Typography>
        In this section, you can explore which alternative crop rotations may potentially be of interest
        to your farm. To decide which alternative crop rotations are most relevant to your own
        circumstances, you can ask yourself the following guidance questions:
      </Typography>
      <div style={{paddingLeft: '30px'}}>
        <List>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to increase my cropping intensity? Does my current rotation contain
              fallow periods with sufficient rainfall for adding a further crop?
            </Typography>
          </ListItem>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to decrease my cropping intensity? Does my current rotation contain
              too many crops that prevent the sufficient accumulation of water in the soil profile
              during fallow periods?
            </Typography>
          </ListItem>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to shift to other crop types? Does my current rotation contain
              too many crops that provide low gross margins? Does my current rotation too exclusively
              rely on cereals and would benefit from the (further) inclusion of legumes?
            </Typography>
          </ListItem>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to reduce my downside risk? Does it cause problems for my farm enterprise
              that my current rotation leads to extreme reductions of my gross margin in bad years?
            </Typography>
          </ListItem>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to cultivate a rotation that provides a higher average gross margin
              across multiple years - while I am willing to accept high fluctuation between years
              (i.e. extreme reductions to my gross margin in bad years)?
            </Typography>
          </ListItem>
          <ListItem sx={{padding: 0, listStyleType: "disc", display: "list-item"}}>
            <Typography fontStyle="italic">
              Would it be of value to shift to a crop rotation that provides a better performance regarding
              one or several environmental indicators (e.g. surface organic matter, soil carbon, soil nitrogen)?
            </Typography>
          </ListItem>
        </List>

        <Typography sx={{marginTop: "10px"}} >
          Based on these and further own considerations, you can select alternate cropping strategy
          in the next steps.
        </Typography>
      </div>
    </div>
  );
}

export default ExploreQuestions;
