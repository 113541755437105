import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button, Grid, Tooltip} from "@mui/material";
import miscHelper from "../ra_Helpers/miscHelper";
import ResultIndicatorModal from "../ra_components/ResultIndicatorModal";
import SeasonalCondModal from "../ra_components/SeasonalCondModal";
import {
  selectCrops,
  selectSectionCIndicators,
  selectSeasonalConditions as previousSeasonalConditions, selectResultIndicator, selectResultIndicatorObj
} from "../features/rotationSlice";
import {selectWaterBalance} from "../features/sitesSlice";
import {anycaseEquals, CDFValues, FilterWaterBalance, makeAColour, SummariseDataByIndicator} from "../functions";
import styles from "../ra_components/sharedPageStyles.module.scss";
import InfoButton from "../ra_components/InfoButton";
import BoxChart from "../ra_components/BoxChart";
import LineChart2 from "../ra_components/LineChart2";
import CDFChart2 from "../ra_components/CDFChart2";
import ParallelChart from "../ra_components/ParallelChart";

const seasonalCond = miscHelper.seasonalClimateConditions();
const allRotationLevelIndicators = miscHelper.compareRotationLevelIndicators();

const CompareRotationLevel = (props) => {
  const effectDone = useRef(false);
  const dispatch = useDispatch();
  const [lineChartTitle, setLineChartTitle] = useState('');
  const [yearlyResults, setYearlyResults] = useState([]);
  const [parallelSummaryData, setParallelSummaryData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [cdfChartData, setCDFChartData] = useState([]);
  const [applicableWB, setApplicableWB] = useState([]);
  const [seriesData, setSeriesData] = useState([]);

  const allIndicators = useSelector(selectSectionCIndicators);
  const crops = useSelector(selectCrops);
  const waterBalance = useSelector(selectWaterBalance);

  const selectableIndicators = useMemo(() => {
    return allIndicators.filter(r => r.selectable);
  }, [allIndicators]);

  const currentIndicatorDesc = useMemo(() => {
    // console.log('CompareRotationLevel memo currentIndicatorDesc');
    if (props.CurrentIndicator) {
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        setLineChartTitle(`Annual results: ${indicatorObj.display}`);
        return indicatorObj.display;
      } else {
        return '';
      }
    }

  }, [props.CurrentIndicator]);

  const currentIndicatorObj = useMemo(() => {
    if (props.CurrentIndicator) {
      // console.log('CompareRotationLevel memo currentIndicatorObj');
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj;
      } else {
        return null;
      }
    }
  }, [props.CurrentIndicator]);

  const currentUnits = useMemo(() => {
    // console.log('CompareRotationLevel memo currentUnits');
    if (props.CurrentIndicator) {
      const indicatorObj = allRotationLevelIndicators.find(r => anycaseEquals(r.value, props.CurrentIndicator));
      if (indicatorObj) {
        return indicatorObj.units;
      } else {
        return '';
      }
    }
  }, [props.CurrentIndicator]);

  const parallelIndicators = useMemo(() => {
    // console.log('CompareRotationLevel memo parallelIndicators');
    const filteredIndicators = allIndicators.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);
    return filteredIndicators;
  }, [allIndicators]);

  const selectedWaterBalance = useMemo(() => {
    let results = [];
    if (props.CurrentConditions) {
      results = waterBalance.filter(wb => props.CurrentConditions.includes(wb.condition));
    }
    return results;
  }, [props.CurrentConditions, waterBalance]);

  //region Prepare data for charts.
  useEffect(() => {
    // console.log('CompareRotationLevel useEffect');
    if (props.CompareData) {
      setSeriesData([...formatLineChartData()]);
      setCDFChartData([...formatCDFChartData()]);
      const resultsData = formatParallelData();
      setParallelSummaryData(resultsData);
    }
  }, [props.CompareData, currentIndicatorObj]);


  const formatLineChartData = () => {
    // Using props.CompareData.treat_seasonal
    const results = [];
    let indicatorFieldName = '';

    if (currentIndicatorObj) {
      indicatorFieldName = currentIndicatorObj.value;

      props.CompareData.rotations.forEach(rotation => {
        // Create a series for each rotation.
        let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE);
        if (rotation.Type === 'reference') {
          seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
        }

        const rotationId = rotation.RotationId;
        const colourThisSeries = makeAColour(seriesColour.colour);

        const rotationSeries = {
          title: `${rotation.Type}`,
          rotationId: rotationId,
          rotation: rotation.Type,
          color: colourThisSeries,
          name: `${rotation.Type}`,
          data: [],
        }

        const yearly_values = props.CompareData.treat_seasonal.filter(t => t.BaseRotationId === rotationId);
        yearly_values.forEach(yv => {
          // const myValue = yv[indicatorFieldName];
          const myValue = yv[props.CurrentIndicator];
          if (myValue !== null) {
            const data_point = {
              x: yv.year,
              y: yv[indicatorFieldName],
            }
            rotationSeries.data.push(data_point);
          }
        });

        results.push(rotationSeries);
      });
    }

    return results;
  };

  const formatCDFChartData = () => {
    const results = [];
    let indicatorFieldName = '';
    console.log('Current Indicator', props.CurrentIndicator);

    if (currentIndicatorObj) {
      indicatorFieldName = currentIndicatorObj.value;

      props.CompareData.rotations.forEach(rotation => {
        // Create a series for each rotation.
        let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE);
        if (rotation.Type === 'reference') {
          seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
        }

        const rotationId = rotation.RotationId;
        const colourThisSeries = makeAColour(seriesColour.colour);

        const resultEntry = {
          color: colourThisSeries,
          title: `${rotation.Type}`,
          rotationId: rotationId,
          rotation: rotation.RotationName,
          name: `${rotation.Type}`,
          id: `${rotation.Type}`,
          marker: {enabled: false},
          data: [],
        }

        const data_values = props.CompareData.treat_seasonal.filter(cv => cv.BaseRotationId === rotationId);
        // const field_values = data_values.map(i => i[indicatorFieldName]);
        const field_values = data_values.map(i => i[props.CurrentIndicator]);
        const cdf_values = CDFValues(field_values, false);
        resultEntry.data = cdf_values.slice(0);

        results.push(resultEntry);
      });
    }

    return results;
  };

  const formatParallelData = () => {
    let colourIndex = 0;

    const resultsSummary = {
      indicators: [...parallelIndicators],
      maxMins: {},
      series: [],
    };

    // Get the max/mins from the data.
    parallelIndicators.forEach(ind => {
      const minPropName = `${ind.value}_min`;
      const maxPropName = `${ind.value}_max`;

      const maxMinObj = {
        min: props.CompareData["minmax"][0][minPropName],
        max: props.CompareData["minmax"][0][maxPropName],
      };

      resultsSummary.maxMins[ind.value] = {...maxMinObj};
    });

    props.CompareData.rotations.forEach((rot, index) => {

      let seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.ALTERNATE1);
      if (rot.Type === 'reference') {
        seriesColour = miscHelper.getCompareColour(miscHelper.CompareColours.REFERENCE)
      }

      const seriesObj = {
        color: seriesColour.colour,
        name: rot["Type"],
        data: [],
        shadow: false,
      };

      const values = props.CompareData["treat_timeAgg"].find(d => d.BaseRotationId === rot.RotationId);

      parallelIndicators.forEach(ind => {
        const indicator = ind.value;

        seriesObj.data.push(values[indicator]);
      });

      resultsSummary.series.push(seriesObj);
      colourIndex += 1;
    });

    // console.log('COmpareRotationLevel resultsSummary', resultsSummary);
    return resultsSummary;
  };
  //endregion

  const handleResultIndicatorChange = (index, new_indicator) => {
    // console.log('CompareRotationLevel handleIndicatorChange', index, new_indicator);
    props.OnResultIndicatorChange(new_indicator);
  };

  const handleSeasonalConditionsChange = (conditions) => {
    // console.log('CompareRotationLevel handleConditionsChange', conditions);
    props.OnSelectedConditionsChange(conditions);
  };

  // Update keys to force chart rerender.
  const linechartKey = Date.now();
  const CDFchartKey = Date.now();

  return (
    <Box >
      <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
        <div>
          <ResultIndicatorModal
            CurrentIndicator={props.CurrentIndicator}
            CurrentIndicatorDesc={currentIndicatorDesc }
            SelectableIndicators={allRotationLevelIndicators}
            OnResultIndicatorChange={handleResultIndicatorChange}
            Label='Result Indicator:'
            LabelBefore={false}
            WithBorder={false}
          />
        </div>
        <div style={{marginLeft: '10px'}}>
          <SeasonalCondModal
            SelectedConditions={props.CurrentConditions}
            OnSeasonalConditionsChange={handleSeasonalConditionsChange}
            Label="Selected Seasons:"/>
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="parchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_par_axis" Position='auto' AnchorTo="parchartcontainer">
                <ParallelChart
                  SummaryData={parallelSummaryData}
                  Crops={crops}
                  Explanation=''
                  InfoKey=''
                  TopScale={true}
                />
              </InfoButton>
            </div>
          </Grid>

          <Grid item xs={7}>
            <div id="linechartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
                <LineChart2
                  SeasonalYears={selectedWaterBalance}
                  Series={seriesData}
                  Title={lineChartTitle}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  Crops={crops}
                  Units={currentUnits}
                  key={linechartKey}
                />
              </InfoButton>
            </div>
          </Grid>

          <Grid item xs={5}>
            <div id="cdfchartcontainer" className={styles.graphPadded}>
              <InfoButton InfoKey="cl_cdf_chart" Position='auto' AnchorTo="cdfchartcontainer">
                <CDFChart2
                  Series={cdfChartData}
                  ResultIndicator={props.CurrentIndicator}
                  ResultIndicatorDesc={currentIndicatorDesc}
                  SeasonalConditions={props.CurrentConditions}
                  Units={currentUnits}
                  key={CDFchartKey}
                />
              </InfoButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

CompareRotationLevel.propTypes = {
  CompareData: PropTypes.object.isRequired,
  CurrentIndicator: PropTypes.string.isRequired,
  CurrentConditions: PropTypes.array.isRequired,
  OnResultIndicatorChange: PropTypes.func,
  OnSelectedConditionsChange: PropTypes.func,
};

CompareRotationLevel.defaultProps = {
  OnResultIndicatorChange: () => {},
  OnSelectedConditionsChange: () => {},
};

export default CompareRotationLevel;
