import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import InfoButton from "../ra_components/InfoButton";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {capitalise, CalcBandYears, ColourDarken, ColourLuminance, getOverallMinMax, makeAColour} from "../functions";

import styles from './sharedPageStyles.module.scss';

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const bandColour = 'rgba(255,20,20,0.1)';

const LineChart2 = (props) => {
  const effectDone = useRef(false);
  const [finalYear, setFinalYear] = useState();

  const plotBands = useMemo(() => {
    let results = [];

    if (props.SeasonalYears && props.SeasonalYears.length > 0) {
      props.SeasonalYears.forEach((y, index) => {

        const friendlyName = `${y.year} ${y.season} ${y.condition}`;
        const bandYears = CalcBandYears(y.season, y.year);

        // Check if we overflow the data.
        if (finalYear !== 0) {
          if (bandYears.X2 > finalYear) {
            bandYears.X2 = finalYear;
          }
        }

        const newBand = {
          color: bandColour,
          from: bandYears.x1,
          to: bandYears.x2,
          tooltipText: friendlyName,
          id: `${y.year}_${y.season}`,
        };

        results.push(newBand);
      });
    }

    return results;
  }, [props.SeasonalYears]);

  const getYAxisTitle = () => {
    let result = '';
    if (props.YAxisTitle === '') {
      result = capitalise(props.ResultIndicator);
    } else {
      result = props.YAxisTitle;
    }

    if (props.Units !== '') {
      result += ` (${props.Units})`;
    }

    return result;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Annual results: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  const getChartOptions = () => {
    return {
      chart : {
        type : 'line',
        zoomType : 'x',
        spacingRight : 20,
        events: {
          load: function() {
            const chart = this;
            // console.log(chart);
            chart.plotBandsToolTip = [];

            if (chart.xAxis[0].plotLinesAndBands) {
              chart.xAxis[0].plotLinesAndBands.forEach((plotBand, index) => {
                if (plotBand.options.tooltipText) {
                  Highcharts.addEvent(plotBand.svgElem.element, 'mousemove', e => {
                    const tooltipText = plotBand.options.tooltipText;
                    const x = e.x + 4;
                    const y = e.y + 8;

                    if (!chart.plotBandsToolTip[index]) {
                      chart.plotBandsToolTip[index] = chart.renderer.label(
                        tooltipText, x, y, 'callout'
                      ).attr({
                        fill: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 10,
                        padding: 5,
                        r: 5
                      }).css({
                        color: '#ffffff',
                        transition: 'opacity 0.5s'
                      }).add();
                    } else {
                      chart.plotBandsToolTip[index].attr({
                        x, y
                      }).css({
                        opacity: 1
                      });
                    }
                  });

                  Highcharts.addEvent(plotBand.svgElem.element, 'mouseout', () => {
                    chart.plotBandsToolTip[index].css({
                      opacity: 0
                    });
                  });
                }
              });
            }
          }
        }
      },
      title : {
        text : getTitle(),
      },
      credits: {
        enabled: false
      },
      rangeSelector : {
        selected : 3,
      },
      legend : {
        enabled : true,
      },
      xAxis : {
        enabled : true,
        type: 'datatime',   // Maybe should be linear?
        maxZoom : 2,
        title : {
          text : "Year",
        },
        plotBands: [...plotBands],
      },
      yAxis : {
        min: props.YAxisMin,
        max: props.YAxisMax,
        title : {
          text : getYAxisTitle(),
        }
      },
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.key}</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f} ' + props.Units + '</b></td></tr>',
        footerFormat : '</table>',
        shared : true,
        useHTML : true,
        valueDecimals : 2
      },
      plotOptions: {
        series: {
          lineWidth: 3,
          label: {
            connectorAllowed: false,
          },
          events: {
            legendItemClick: function() {
              const xAxis = this.chart.xAxis[0];
              if (this.name === 'Selected Seasons') {
                if (this.visible) {
                  xAxis.update({
                    plotBands: []
                  });
                } else {
                  xAxis.update({
                    plotBands: plotBands
                  });
                }
              }
            }
          }
        }
      },
      series: props.Series,
    }
  };

  return (
    <div className={styles.graphContainer}>
      <InfoButton InfoKey="cl_line_chart" Position='auto' AnchorTo="linechartcontainer">
        {props.Series && <HighchartsReact
          highcharts={Highcharts}
          options={getChartOptions()}
          constructorType={ 'chart' }
        />}
      </InfoButton>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

LineChart2.propTypes = {
  Series: PropTypes.array.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
  SeasonalYears: PropTypes.array,
  YAxisMin: PropTypes.number,
  YAxisMax: PropTypes.number,
};

LineChart2.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  InfoKey: '',
  SeasonalYears: [],
  YAxisMin: null,
  YAxisMax: null,
 };

export default LineChart2;
