import React, {useState, useEffect, useRef} from 'react';
import IFrameWrapper from "../components/IFrameWrapper";
import { useLocation } from "react-router-dom";

const CAMainPage = () => {
  const effectDone = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (effectDone.current === false) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }

    return () => {
      effectDone.current = true;
    }
  }, [location]);

  return (
    <IFrameWrapper Source="https://cacs.usqresearch.edu.au/climateARM/?theme=blank" id="tool_start_ClimateARM" />
  )
}

export default CAMainPage;
