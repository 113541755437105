import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import styles from './sharedPageStyles.module.scss';
import {capitalise, CDFValues, ColourDarken, ColourLuminance, getOverallMinMax, makeAColour} from "../functions";
import InfoIcon from "../ra_images/infoIcon.png";
import LineChart from "./LineChart";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);

const CDFChart = (props) => {
  const effectDone = useRef(false);
  const [chartData, setChartData] = useState([]);
  const [chartMinData, setChartMinData] = useState(null);
  const [chartMaxData, setChartMaxData] = useState(null);

  useEffect(() => {
    if (effectDone.current === true) {
      getChartData();
    }

    return () => {
      effectDone.current = true;
    }
  }, [props.ResultsData, props.ResultIndicator, props.SeasonalConditions]);

  const getCropColour = (cropname, withGradient = true, makeDarker = false) => {
    let result = null;

    const lcCropName = cropname.toString().trim().toLowerCase();
    const aCrop = props.Crops.find(c => c.Name.toLowerCase() === lcCropName);

    if (aCrop) {
      const startColour = makeAColour(aCrop.bg_colour);

      if (withGradient) {
        const stopColour = ColourLuminance(startColour, 0.2);
        const perShapeGradient = {x1: 0, y1: 0, x2: 1, y2: 1};
        const gradient = {
          linearGradient: perShapeGradient,
          stops: [[0, startColour], [1, stopColour]],
        };

        result = gradient;
      } else {
        result = makeAColour(startColour);

        if (aCrop.neverDarken === 0) {
          if (makeDarker) {
            result = ColourDarken(startColour, .1);
          }
        }
      }
    }

    return result;
  };

  const getChartData = () => {
    const cropData = {};
    let lastCrop = null;
    const foundCropsSet = new Set();
    let dataMin = null;
    let dataMax = null;

    if (props.ResultsData) {
      if (props.ResultsData.length > 0) {
        props.ResultsData.forEach(d => {
          const cropName = d.crop;
          if (cropName !== 'fallow') {

            if (lastCrop === null || cropName !== lastCrop) {
              foundCropsSet.add(cropName);

              if (!cropData.hasOwnProperty(cropName)) {
                cropData[cropName] = {data: []};
              }
            }
            const thisValue = d[props.ResultIndicator];
            if (thisValue !== null) {
              const roundedValue = Math.round(thisValue * 1000) / 1000;
              cropData[cropName].data.push(roundedValue);
              if (dataMin === null || roundedValue < dataMin) {
                dataMin = roundedValue;
              }
              if (dataMax === null || roundedValue > dataMax) {
                dataMax = roundedValue;
              }
            }

            lastCrop = cropName;
          }
        });

        // Sort the array of values.
        const foundCrops = Array.from(foundCropsSet);
        foundCrops.forEach(c => {
          cropData[c].data.sort((a, b) =>  a - b);
          cropData[c].CDFData = CDFValues(cropData[c].data, true);
          cropData[c].min = cropData[c].data[0];
          cropData[c].max = cropData[c].data[cropData[c].data.length - 1];
        });

        setChartData(cropData);
      }
    }
  };

  const convertToSeries = (cropData) => {
    let results = [];

    if (cropData) {
      const cropKeys = Object.keys(cropData);

      cropKeys.forEach(k => {
        const seriesObj =  {
          name: capitalise(k),
          data: [...cropData[k].CDFData],
          color: getCropColour(k, false, true),
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 4
          },
        };
        results.push(seriesObj);
      });
    }

    // console.log('CDF Data', results);
    return results;
  };

  const getXAxisTitle = () => {
    let result = '';
    if (props.XAxisTitle === '') {
      result = capitalise(props.ResultIndicator);
    } else {
      result = props.XAxisTitle;
    }

    if (props.Units !== '') {
      result += ` (${props.Units})`;
    }

    return result;
  };

  const getYAxisTitle = () => {
    return (props.YAxisTitle === '') ? 'Percentile' : props.YAxisTitle;
  };

  const getTitle = () => {
    if (props.Title === '') {
      return 'Cumulative Distribution: ' + capitalise(props.ResultIndicatorDesc);
    } else {
      return props.Title;
    }
  };

  // const minmaxData = getOverallMinMax(data);

  const getChartOptions = () => {
    return {
      chart : {
        zoomType : 'x',
        events: {
          render: function() {
            var chart = this;
            var r = chart.renderer;

            if (props.InfoKey !== '') {
              if (chart.customGroup) {
                chart.customGroup.destroy();
              }

              chart.customGroup = r.g('customGroup').add();

              var xPos = 10;
              var yPos = 2;
              r.image(InfoIcon, xPos, yPos, 30, 30)
                .on('click', () => {
                  // console.log('Info button clicked')
                })
                .add(chart.customGroup);
            }
          },
        },
      },
      credits: {
        enabled: false
      },
      title : {
        text : getTitle()
      },
      legend : {
        enabled : true
      },
      xAxis : {
        maxZoom : 200,
        enabled: true,
        min: chartMinData,
        max: chartMaxData,
        title: {
          text: getXAxisTitle(),
        }
      },
      yAxis : {
        floor: 0,
        ceiling: 1,
        title : {
          text : getYAxisTitle(),
        }
      },
      tooltip : {
        headerFormat : '<span style="font-size:10px;font-weight:bold">{point.y:.2f} %</span><table>',
        pointFormat : '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.x:.1f} ' + props.Units + '</b></td></tr>',
        footerFormat : '</table>',
        useHTML : true,
        valueDecimals : 2
      },
      plotOptions: {
        line: {
          step: 'left',
        }
      },
      series: convertToSeries(chartData),
    }
  };


  return (
    <div className={styles.graphContainer}>
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions()}
        constructorType={ 'chart' }
      />
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}


CDFChart.propTypes = {
  ResultsData: PropTypes.array.isRequired,
  ResultIndicator: PropTypes.string.isRequired,
  ResultIndicatorDesc: PropTypes.string.isRequired,
  Crops: PropTypes.array.isRequired,
  Units: PropTypes.string.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  YAxisTitle: PropTypes.string,
  XAxisTitle: PropTypes.string,
  InfoKey: PropTypes.string,
};

CDFChart.defaultProps = {
  Title: '',
  Explanation: '',
  YAxisTitle: '',
  XAxisTitle: '',
  InfoKey: '',
};

export default CDFChart;
