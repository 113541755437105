import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import styles from './CheckboxList.module.scss';
import LoadingComponent from './LoadingComponent';

// Options to be passed as an array of:
// {
//   display: string,
//   detail: string,
//   value: string,
// }
// Will be displayed as 'display (details)' and returns value.

const CheckboxList = (props) => {
  const [optionsArray, setOptionsArray] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    // Columnise the options. Keep the order passed.
    const maxRows = Math.ceil(props.Options.length / props.Columns);

    // Setup multidimensional array of sites. This is controlled
    // by the passed Columns value.
    let optionArray = [];
    for(let r = 0; r < maxRows; r++) {
      const thisRow = [];
      for(let c = 0; c < props.Columns; c++) {
        const optionIndex = (c * maxRows) + r;
        if (optionIndex < props.Options.length) {
          const thisOption = {...props.Options[optionIndex]};
          if (!thisOption.hasOwnProperty('value')) {
            thisOption.value = thisOption.display;
          }
          if (!thisOption.hasOwnProperty('detail')) {
            thisOption.detail = '';
          }
          thisRow.push(thisOption);
          // if (props.Options(optionIndex).hasOwnProperty('value')) {
          //   thisRow.push(props.Options[optionIndex]);
          // } else {
          //   // No value property so use the display prop.
          //   thisRow.push({
          //     ...props.Options[optionIndex],
          //     value: props.Options[optionIndex].display
          //   });
          // }
        }
      }
      optionArray.push(thisRow);
    }
    setOptionsArray(optionArray);
    setSelected([...props.Selected]);

  }, [props.Options, props.Columns, props.Selected]);

  // useEffect(() => {
  //   props.OnSelect(selected);
  // }, [selected]);
  const handleToggle = (value) => {
    let newSelected;
    if (!props.MultiSelect) {
      // Single selection only.
      newSelected = [value];
    } else {
      // Multiple selection is allowed,
      const currentIndex = selected.indexOf(value);
      if (currentIndex < 0) {
        newSelected = [...selected, value];
      } else {
        newSelected = selected.filter(x => x !== value);
      }
    }

    setSelected(newSelected);
    props.OnSelect(newSelected);
  };

  //region Setup table styles
  const sxContainer = {
    width: "100%",
    height: "max_content",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 4,
  };
  //endregion

  const isSelected = (optionValue) => {
    return selected.indexOf(optionValue) === -1 ? false : true;
  };

  const renderRow = (optionItemsArray, rowKey) => {
    const cellArray = optionItemsArray.map(optionItem => {
      return  renderCell(optionItem, rowKey);
    });

    return (
      <TableRow key={rowKey}>
        {cellArray}
      </TableRow>
    );
  };

  const renderCell = (optionItem, rowKey) => {
    let cellValue = null;
    if (optionItem.hasOwnProperty('value')) {
      cellValue = optionItem.value;
    } else {
      cellValue = optionItem.display;
    }
    const cellKey = (rowKey + '_' + cellValue).replace(' ', '_');

    let toDisplay = optionItem.display;
    if (optionItem.hasOwnProperty('detail')) {
      if (optionItem.detail !== '') {
        toDisplay += ` (${optionItem.detail})`;
      }
    }
    if (optionItem.hasOwnProperty('details')) {
      if (optionItem.details !== '') {
        toDisplay += ` (${optionItem.details})`;
      }
    }

    return (
      <TableCell
        key={cellKey}
        align="left" style={{...borderStyle}}>
        <FormControlLabel
          control = {
            <Checkbox
              color="primary"
              checked={isSelected(optionItem.value)}
              onChange={() => handleToggle(cellValue)}
            />
          }
          label={toDisplay}>
        </FormControlLabel>
      </TableCell>
    );
  };

  const borderStyle = {
    borderWidth: "0px",
    borderStyle: "solid",
    borderColor: "#000000",
    borderSpacing: 1,
    padding: '0px 16px',
  };

  if (!props.Options) {
    return (
      <LoadingComponent fullwidth={false} dismissable={true} state="loading" />
    );
  } else if (props.Options.length === 0) {
    <LoadingComponent fullwidth={false} dismissable={true} state="loading" />
  }

  return (
    <div className={styles.container}>
      {props.Title !== '' && (<div style={{backgroundColor: props.TitleBackground}}>
        <Typography variant="h6" align="left">
          {props.Title}
        </Typography>
      </div>)}
      <TableContainer  sx={{...sxContainer}}>
        {props.Options && <Table
            sx={{
              width: "60%",
              "&.MuiTable-root": {...borderStyle}
            }}
            size="small"
            aria-label="option list"
            stickyHeader
          >
            <TableHead>
              <TableRow key="header1" >
              </TableRow>
            </TableHead>
            <TableBody>
              {optionsArray.map((row, rowIndex) => {
                const rowKey = `clrow${rowIndex}`;
                return renderRow(row, rowKey);
              })}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </div>
  );
}

CheckboxList.propTypes = {
  Options: PropTypes.arrayOf(Object).isRequired,
  Selected: PropTypes.array,
  Columns: PropTypes.number,
  Title: PropTypes.string,
  MultiSelect: PropTypes.bool,
  OnSelect: PropTypes.func,
  TitleBackground: PropTypes.string,
}


CheckboxList.defaultProps = {
  Selected: [],
  Columns: 1,
  MultiSelect: false,
  OnSelect: () => {},
  TitleBackground: '#ffffff',
}

export default CheckboxList;
