import axiosRotation from "../ra_data/axiosRotation";

class dataHelper {
  constructor() {
    this.data = {};
  }

  statePAWCsFromAPI() {
    // we don't want to call the API every time this function is called
    // if we have cached data, let's return that in a promise
    // and pretend it's an API call that's been successful.
    if (this.data.hasOwnProperty('statePAWC')) {
      return new Promise((resolve, reject) => {
        resolve(this.data.statePAWC);
      });
    };
    var self = this;
    return axiosRotation.get('/statepawc', {})
      .then(function(response) {
        // if we've called the api already, let's cache the results on this class
        self.data.statePAWC = response;
        return response;
      });
  }

  PAWCByState(statePAWCs) {
    var res = {};
    statePAWCs.forEach((row) => {
      const {state, PAWC} = row;
      if (!(state in res)) {
        res[state] = [state];
      } else {
        res[state].push(PAWC);
      }
    });

    return res;
  }

  infoDataFromAPI() {
    // we don't want to call the API every time this function is called
    // if we have cached data, let's return that in a promise
    // and pretend it's an API call that's been successful.
    if (this.data.hasOwnProperty('infoData')) {
      return new Promise((resolve, reject) => {
        resolve(this.data.infoData);
      });
    };
    var self = this;
    return axiosRotation.get('/infodata', {})
      .then(function(response) {
        // if we've called the api already, let's cache the results on this class
        self.data.infoData = response;
        return response;
      });
  }

  getInfoDataDetail(key) {
    if (this.data.hasOwnProperty('infoData')) {
      return new Promise((resolve, reject) => {
        resolve(this.data.infoData);
      });
    };
  }

  CompareColours = {
    REFERENCE: 0,
    ALTERNATE: 1,
    ALTERNATE1: 1,
    ALTERNATE2: 2,
    DEFAULT: 3,
    colours: [
      {name: 'reference', colour: "#99a4e7"},
      {name: 'alternate', colour: "#a7d2a7"},
      {name: 'alternate1', colour: "#a7d2a7"},
      {name: 'alternate2', colour: "#eec39d"},
      {name: 'default', colour: "#f59f9f"}
    ]
  }

  getCompareColour(index = null, name = null) {
    if (index) {
      return this.CompareColours.colours[index];
    }
    if (name) {
      const myColour = this.CompareColours.colours.find(c => c.name === name.toLowerCase());
      if (myColour) {
        return myColour.colour;
      }
    }

    // Nothing found to return default.
    return this.CompareColours.colours[this.CompareColours.DEFAULT];
  }

  // While it is ideal to have the following stored in the database, these options
  // will probably require code changes if new values are added to these lists.
  // This fact minimises the benefits of database storage.

  // Note: The value property MUST always be lowercase. The columns from the database are renamed to match
  //       the value property of this array. This makes setting for the data for charting much easier.
  // Jan'25: resultIndicators will now be known as Crop Level Indicators.
  resultIndicators() {
    const inds = [
      { display: 'Yield', details: '', value: 'yield', infokey: 'ri_yield', units: 'kg/ha', spider: false, spiderSort: 10, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Gross margin', details: '', value: 'gross_margin', infokey: 'ri_gmargin', units: 'AUD', spider: true, spiderSort: 20, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Revenue', details: '', value: 'revenue', infokey: 'ri_revenue', units: '$AUD', spider: false, spiderSort: 30, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Revenue-cost ratio', details: '', value: 'revenue_costratio', infokey: 'ri_rev_cost', units: 'AUD/AUD', spider: true, spiderSort: 40, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Financial water-use efficiency', details: '', value: 'financial_wue', infokey: 'ri_finwue', units: 'AUD/mm', spider: false, spiderSort: 90, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Bio-physical water-use efficiency', details: '', value: 'biophysical_wue', infokey: 'ri_phywue', units: 'kg/mm', spider: true, spiderSort: 120, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop water uptake', details: '', value: 'water_uptake', infokey: 'ri_cwuptake', units: 'mm/ha', spider: true, spiderSort: 70, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop nitrogen uptake', details: '', value: 'n_uptake', infokey: 'ri_cnuptake', units: 'kg/ha', spider: true, spiderSort: 80, selectable: true, axisStep: null, axisReverse: false},
      { display: 'In-crop rainfall', details: '', value: 'incrop_rain', infokey: 'ri_crain', units: 'mm', spider: false, spiderSort: 100, selectable: true, axisStep: null, axisReverse: false},
      { display: 'In-crop water loss', details: '', value: 'incrop_water_loss', infokey: 'ri_cwloss', units: 'mm/ha', spider: true, spiderSort: 60, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Biomass', details: '', value: 'biomass', infokey: 'ri_biomass', units: 'kg/Ha', spider: false, spiderSort: 110, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop failure frequency', details: '', value: 'crop_failure', infokey: 'ri_cfail', units: 'fraction', spider: true, spiderSort: 50, selectable: false, axisStep: null, axisReverse: true, axisMinValue: 0},
    ];

    return inds;
  }

  // Jan'25: sectionCIndicators will now be known as Rotation Level Indicators.
  sectionCIndicators() {
    const inds = [
      { display: 'Achieved cropping intensity', details: '', value: 'crop_intensity', infokey: '', units: 'crops/year', spider: true, scatter: 30, spiderSort: 10, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop failure', details: '', value: 'crop_failure', infokey: '', units: '', spider: true, scatter: 0, spiderSort: 20, selectable: true, axisStep: null, axisReverse: true, minAxisValue: 0},
      { display: 'Gross margin', details: '', value: 'gross_margin', infokey: '', units: 'AUD/ha', spider: true, scatter: 10, spiderSort: 30, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Downside risk', details: '', value: 'downside_risk', infokey: '', units: 'AUD/ha', spider: true, scatter: 20, spiderSort: 40, selectable: true, axisStep: null, axisReverse: true, axisMinValue: 0},
      { display: 'Water loss', details: '', value: 'water_loss', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 50, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop water uptake', details: '', value: 'water_uptake', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 60, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop nitrogen uptake', details: '', value: 'n_uptake', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 70, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Soil organic nitrogen', details: '', value: 'organic_n', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 80, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Soil organic carbon', details: '', value: 'organic_c', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 90, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Surface organic matter', details: '', value: 'organic_matter', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 100, selectable: true, axisStep: null, axisReverse: false},
    ];

    return inds;
  }

  exploreIndicators() {
    const inds = [
      { display: 'Gross margin', details: '', value: 'gross_margin', infokey: '', units: 'AUD', spider: true, scatter: 10, spiderSort: 10, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Downside risk', details: '', value: 'downside_risk', infokey: '', units: 'AUD/ha', spider: false, scatter: 20, spiderSort: 20, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Revenue', details: '', value: 'revenue', infokey: '', units: 'AUD/ha', spider: false, scatter: 20, spiderSort: 30, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Variable costs', details: '', value: 'variable_costs', infokey: '', units: 'AUD/ha', spider: false, scatter: 20, spiderSort: 40, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Revenue-cost ratio', details: '', value: 'revcost_ratio', infokey: '', units: 'AUD/AUD', spider: false, scatter: 20, spiderSort: 50, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Achieved cropping intensity', details: '', value: 'crop_intensity', infokey: '', units: 'crops/year', spider: true, scatter: 30, spiderSort: 60, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop failure', details: '', value: 'crop_failure', infokey: '', units: 'fraction', spider: true, scatter: 0, spiderSort: 70, selectable: true, axisStep: null, axisReverse: true, minAxisValue: 0},
      { display: 'Financial water-use efficiency', details: '', value: 'financial_wue', infokey: '', units: 'AUD/mm', spider: false, scatter: 20, spiderSort: 80, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Financial fertiliser use efficiency', details: '', value: 'financial_fue', infokey: '', units: 'AUD/kg', spider: false, scatter: 20, spiderSort: 90, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop water uptake', details: '', value: 'water_uptake', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 100, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop nitrogen uptake', details: '', value: 'n_uptake', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 110, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Water loss', details: '', value: 'water_loss', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 120, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Soil organic carbon', details: '', value: 'organic_c', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 130, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Soil organic nitrogen', details: '', value: 'organic_n', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 140, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Surface organic matter', details: '', value: 'organic_matter', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 150, selectable: true, axisStep: null, axisReverse: true},
      { display: 'In-crop rainfall', details: '', value: 'incrop_rain', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 160, selectable: true, axisStep: null, axisReverse: true},
    ];

    inds.sort((a, b) => a.spiderSort - b.spiderSort);

    return inds;
  }

  // Updated Jan'25: Rotation Level Indicators on the compare page.
  compareRotationLevelIndicators() {
    const inds = [
      { display: 'Gross margin', details: '', value: 'gross_margin', infokey: '', units: 'AUD/ha', spider: true, scatter: 10, spiderSort: 30, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Revenue', details: '', value: 'revenue', infokey: '', units: 'AUD/ha', spider: false, scatter: 20, spiderSort: 30, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Revenue-cost ratio', details: '', value: 'revcost_ratio', infokey: '', units: 'AUD/AUD', spider: false, scatter: 20, spiderSort: 50, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Financial water-use efficiency', details: '', value: 'financial_wue', infokey: '', units: 'AUD/mm', spider: false, scatter: 20, spiderSort: 80, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Water loss', details: '', value: 'water_loss', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 50, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop water uptake', details: '', value: 'water_uptake', infokey: '', units: 'mm/ha', spider: true, scatter: 0, spiderSort: 60, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Crop nitrogen uptake', details: '', value: 'n_uptake', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 70, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Soil organic nitrogen', details: '', value: 'organic_n', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 80, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Soil organic carbon', details: '', value: 'organic_c', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 90, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Surface organic matter', details: '', value: 'organic_matter', infokey: '', units: 'kg/ha', spider: true, scatter: 0, spiderSort: 100, selectable: true, axisStep: null, axisReverse: false},
    ];

    return inds;
  }

  // Note: This needs to be kept in line with the similar function in the UI.
  compareIndicators() {
    const inds = [
      { display: 'Gross margin', details: '', value: 'gross_margin', infokey: '', units: '$AUD/Ha', dbField: 'grossMargin_ha_recentCosts_longTermOutPrice_realAUD_yrAvg_plotAvg', lessIsBest: false, spider: true, scatter: 10, spiderSort: 10, selectable: true, axisStep: null, axisReverse: false},
      { display: 'Revenue', details: '', value: 'revenue', infokey: '', units: '$AUD/Ha', dbField: 'revenue_timeAvg_realAUD_yrAvg_plotAvg', lessIsBest: false, spider: false, scatter: 20, spiderSort: 30, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Variable costs', details: '', value: 'variable_costs', infokey: '', units: '$AUD/Ha', dbField: 'recentCosts_total_realAUD_yrAvg_plotAvg', lessIsBest: false, spider: false, scatter: 20, spiderSort: 40, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Revenue-cost ratio', details: '', value: 'revcost_ratio', infokey: '', units: '', dbField: 'revenueCostRatio_plotAvg', lessIsBest: false, spider: false, scatter: 20, spiderSort: 50, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop failure', details: '', value: 'crop_failure', infokey: '', units: '', dbField: 'cropsFailed_frac_plotAvg', lessIsBest: true, spider: true, scatter: 0, spiderSort: 70, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Financial water-use efficiency', details: '', value: 'financial_wue', infokey: '', units: '$AUD/mm', dbField: 'WUE_financial_audperMM_yrAvg_plotAvg', lessIsBest: false, spider: false, scatter: 20, spiderSort: 80, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Financial fertiliser use efficiency', details: '', value: 'financial_fue', infokey: '', units: '$AUD/kg', dbField: 'FertUE_financial_AUDperKgN_yrAvg_plotAvg', lessIsBest: false, spider: false, scatter: 20, spiderSort: 90, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop water uptake', details: '', value: 'water_uptake', infokey: '', units: 'mm/Ha', dbField: 'cropWaterUptake_mm_yrAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 100, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Crop nitrogen uptake', details: '', value: 'n_uptake', infokey: '', units: 'kg/Ha', dbField: 'cropNitrogenUptake_kgHa_yrAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 110, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Water loss', details: '', value: 'water_loss', infokey: '', units: 'mm/Ha', dbField: 'waterLoss_mm_yrAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 120, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Soil organic carbon', details: '', value: 'organic_c', infokey: '', units: 'kg/Ha', dbField: 'soilOrganicC_fullProfile_kgHa_stateAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 130, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Soil organic nitrogen', details: '', value: 'organic_n', infokey: '', units: 'kg/Ha', dbField: 'soilOrganicN_fullProfile_kgHa_stateAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 140, selectable: true, axisStep: null, axisReverse: true},
      { display: 'Surface organic matter', details: '', value: 'organic_matter', infokey: '', units: 'kg/Ha', dbField: 'SurfOM_kgHa_stateAvg_plotAvg', lessIsBest: false, spider: true, scatter: 0, spiderSort: 150, selectable: true, axisStep: null, axisReverse: true},
    ];

    inds.sort((a, b) => a.spiderSort - b.spiderSort);

    return inds;
  }

  seasonalClimateConditions() {
    const scc = [
      { display: 'Extremely dry', details: 'water balance ratio <= 0.15', value: 'extremely dry', infokey: null},
      { display: 'Moderately dry', details: '0.15 < water balance ratio <= 0.3', value: 'moderately dry', infokey: null},
      { display: 'Mildly dry', details: '0.3 < water balance ratio <= 0.45', value: 'mildly dry', infokey: null},
      { display: 'Mildly wet', details: '0.45 < water balance ratio <= 0.6', value: 'mildly wet', infokey: null},
      { display: 'Moderately wet', details: '0.6 < water balance ratio <= 0.75', value: 'moderately wet', infokey: null},
      { display: 'Extremely wet', details: '0.75 < water balance ratio', value: 'extremely wet', infokey: null},
    ];

    return scc;
  }

  nitrogenApplied() {
    const na = [
      {display: 'Low', details: 'cereals: 75 kg N/ha; oilseeds: 50 kg N/ha; pulses: 0 kg N/ha', value: 'low', infokey: null},
      {display: 'Medium', details: 'cereals: 125 kg N/ha; oilseeds: 75 kg N/ha; pulses: 25 kg N/ha', value: 'medium', infokey: null},
      {display: 'High', details: 'cereals: 175 kg N/ha; oilseeds: 125 kg N/ha; pulses: 50 kg N/ha', value: 'high', infokey: null},
    ];

    return na;
  }


  soilPAWCOptions(state) {
    // No Longer Used. This is now data driven.
    const pawc = [
      {display: '80mm', details: '', value: '80', infokey: null},
      {display: '120mm', details: '', value: '120', infokey: null},
      {display: '150mm', details: '', value: '150', infokey: null},
      {display: '190mm', details: '', value: '190', infokey: null},
      {display: '240mm', details: '', value: '240', infokey: null},
    ];

    return pawc;
  }

  PAWCOptions(PAWCArray) {
    let pawc = [];

    if (PAWCArray) {
      if (Array.isArray(PAWCArray)) {
        if (PAWCArray.length) {
          PAWCArray.forEach(p => {
            const pawcOption = {
              display: `${p}mm`,
              details: '',
              value: p,
              infokey: null
            };

            pawc.push(pawcOption);
          });
        }
      }
    }
    return pawc;
  }
}

const miscHelper = new dataHelper();

export default miscHelper;
