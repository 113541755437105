import React, {useState, useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import pageStyles from './Results.module.scss';
import RefRotation from "../ra_components/RefRotation";
import {
  selectBaseRotationObj,
  selectCrops,
  selectNitrogenLevel,
  selectSowingRuleId,
  selectSectionCIndicators
} from "../features/rotationSlice";
import {selectSowingRuleById} from "../features/sowingRulesSlice";
import {capitalise, SummariseDataByIndicator} from "../functions";
import ResultsCropLevel from "./ResultsCropLevel";
import ResultsRotationLevel from "./ResultsRotationLevel";
import {selectRotationLevelData} from "../features/resultsSlice";
import { useLocation } from "react-router-dom";

//region Defined styles Accordion components
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={4} square={false} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '10px',
  marginTop: '2px',
  backgroundColor: 'lightgray',
  color: 'black',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.4rem', color: '#fefefe'}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  // flexDirection: 'row-reverse',   Commenting this out puts icons on the right
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 'bold',
  color: '#fefefe',
  marginLeft: '10px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .7)',
  padding: theme.spacing(0),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//endregion


// ***************************************************************
// There has been a change in definitions regarding the different
// 'sections' display by this component. Section B was previously
// titled 'Crop Level results'. Now Section B is 'Rotation Level
// results' however the component which displays this information
// is still called 'ResultsSectionC' and visa versa for Section C.
// ***************************************************************

const Results = (props) => {
  const [expandedAry, setExpandedAry] = useState(['sectionA', 'sectionB', 'sectionC']);
  const [targetIntensity, setTargetIntensity] = useState(0);
  const baseRotationObj = useSelector(selectBaseRotationObj);
  const location = useLocation();

  const crops = useSelector(selectCrops);
  const sowingRuleId = useSelector(selectSowingRuleId);
  const sowingRuleObj = useSelector((state) => selectSowingRuleById(state, sowingRuleId || 0));
  const NLevel = useSelector(selectNitrogenLevel);
  const sowingRuleDesc = sowingRuleObj?.Description || '';
  const resultsData = useSelector(selectRotationLevelData);
  const allIndicators = useSelector(selectSectionCIndicators);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  useEffect(() => {
    // ('Results - ResultsData', resultsData);

    if (baseRotationObj) {
      setTargetIntensity(baseRotationObj.targetedCrops);
    }

    if (resultsData && allIndicators) {

      const cropIntensityIndicators = allIndicators.filter(r => r.value === 'crop_intensity');

      const summaryData = SummariseDataByIndicator(
        resultsData, cropIntensityIndicators, crops, ['fallow']
      );
      // console.log('Results - SummaryData', summaryData)
    }
  }, [baseRotationObj, resultsData, allIndicators]);

  const inExpanded = (panelName) => {
    if (expandedAry.indexOf(panelName) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const toggleExpanded = (panelName) => {
    let newExpanded;
    if (expandedAry.indexOf(panelName) !== -1) {
      // In the array so remove it.
      newExpanded = expandedAry.filter(x => x !== panelName);
    } else {
      newExpanded = [...expandedAry, panelName];
    }

    setExpandedAry(newExpanded);
  };

  return (
    <div style={{padding: 2}}>
      <Accordion
        expanded={inExpanded('sectionA')}
        onChange={() => toggleExpanded('sectionA')}>
        <AccordionSummary aria-controls="sectionA-content" id="sectionA-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}
          >
            A. Selected reference crop rotation
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{maxHeight: '145px'}}>
          <div className={pageStyles.tableContainer}>
            <RefRotation
              Rotation={baseRotationObj}
              Crops={crops}
              SowingRule={sowingRuleDesc}
              NFertLevel={capitalise(NLevel)}/>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionB')}
        onChange={() => toggleExpanded('sectionB')}>
        <AccordionSummary aria-controls="sectionB-content" id="sectionB-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}
          >
            B. Rotation-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResultsRotationLevel />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={inExpanded('sectionC')}
        onChange={() => toggleExpanded('sectionC')}>
        <AccordionSummary aria-controls="sectionC-content" id="sectionC-header">
          <Typography
            sx={{ width: '33%', flexShrink: 0, ml: 4, color: 'black'}}
            fontWeight="bold"
            fontSize={20}
          >
            C. Crop-level results
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResultsCropLevel />
        </AccordionDetails>
      </Accordion>

    </div>
  );
};

export default Results;
