import React from 'react';
import { Box, Tabs, Tab, Stack } from '@mui/material';
import { textTransform } from '@mui/system';
import PropTypes from 'prop-types';

const NavBar = (props) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                sx={{ color: 'white', backgroundColor: '#4e7707' }}
                value={false}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    {props.navItems.map((navItem) => {
                        return (
                            <Tab
                                sx={{opacity: '1', textTransform: 'none'}}
                                key={navItem.label}
                                label= {navItem.label}
                                href={navItem.link}
                                id={`tool_select_${navItem.label}`}
                            />
                        )
                    })}
                </Stack>
            </Tabs>
        </Box>
    );
}

NavBar.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object)
}

export default NavBar;
