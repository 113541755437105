import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import axiosRotation from "../ra_data/axiosRotation";
import {sitesByStateObj, siteLatLongInfoObj} from '../functions';

const initialState = {
  sites: [],
  site: null,   // currently selected siteId
  name: null,   // ditto
  pawc_values: [],
  status: 'idle',
  error: null,
  PAWCstatus: 'idle',
  PAWCerror: null,
  waterBalanceStatus: 'idle',
  waterBalanceError: null,
  waterBalance: [],
  pawc: 0,
}

export const fetchSites = createAsyncThunk('sites/fetchSites', async (arg, {getState}) => {
  try {
    const url = `/sites`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchWaterBalance = createAsyncThunk(
  'sites/fetchWaterBalance',
  async ({siteId}, {getState}) => {
  try {
    const url = `/sitewaterbalance?siteId=${siteId}`;
    const response = await axiosRotation.get(url);
    return response.data;
  } catch (err) {
    return err.message;
  }
});

export const fetchPAWCs = createAsyncThunk(
  'sites/fetchPAWCs',
  async ({siteId}, {getState}) => {
  try {
    if (siteId) {
      const url = `/sitepawc?siteId=${siteId}`;
      const response = await axiosRotation.get(url);
      return response.data;
    }
  } catch (err) {
    return err.message;
  }
});

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    updateSite: (state, action) => {
      // Payload is expected to be a site id.
      const siteId = action.payload;
      state.site = siteId;

      const selSite = state.sites.find(s => s.id === siteId);
      if (selSite) {
        state.name = selSite.Name;
      }
    },
    resetPAWCs: (state, action) => {
      // Payload is expected to be a pawc value(number).
      state.pawc_values = [];
      state.pawc = 0;
    },
    updatePAWC: (state, action) => {
      // Payload is expected to be a pawc value(number).
      state.pawc = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSites.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        state.status = 'success';
        state.sites = action.payload.sites;
      })
      .addCase(fetchSites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPAWCs.pending, (state, action) => {
        state.PAWCstatus = 'loading';
      })
      .addCase(fetchPAWCs.fulfilled, (state, action) => {
        state.PAWCstatus = 'success';
        state.pawc_values = action.payload;
      })
      .addCase(fetchPAWCs.rejected, (state, action) => {
        state.PAWCstatus = 'failed';
        state.PAWCerror = action.error.message;
      })
      .addCase(fetchWaterBalance.pending, (state, action) => {
        state.waterBalanceStatus = 'loading';
      })
      .addCase(fetchWaterBalance.fulfilled, (state, action) => {
        state.waterBalanceStatus = 'success';
        state.waterBalance = action.payload.waterBalance;
      })
      .addCase(fetchWaterBalance.rejected, (state, action) => {
        state.waterBalanceStatus = 'failed';
        state.waterBalanceError = action.error.message;
      })
  },
});

export const {updateSite, updatePAWC, resetPAWCs} = sitesSlice.actions;

export const selectSites = (state) => state.sites.sites;
export const selectSite = (state) => state.sites.site;
export const selectSiteName = (state) => state.sites.name;
export const selectPAWCList = (state) => state.sites.pawc_values;
export const selectPAWC = (state) => state.sites.pawc;
export const selectSiteById = (state, siteId) => {
  return state.sites.sites.find(s => s.id = siteId);
};

const getSiteId = (state) => state.sites.site;
const getSites = (state) => state.sites.sites;

export const selectSiteDetail = createSelector(
  [getSiteId, getSites],
  (siteId, sites) => {
    const siteObj = {
      name: '',
      state: '',
      lat: null,
      long: null,
    };

    if (siteId) {
      if (sites && sites.length > 0) {
        const mySite = sites.find(s => s.id === siteId);
        if (mySite) {
          siteObj.name = mySite.Name;
          siteObj.state = mySite.State;
          siteObj.lat = mySite.Lat;
          siteObj.long = mySite.Lon;
        }
      }
    }

    return siteObj;
  }
);

export const selectSitesByState = (state) => {
  let sitesObj = {};
  if (state.sites.status === 'success') {
    sitesObj = sitesByStateObj(state.sites.sites);
  }

  return sitesObj;
};

export const selectSiteLatLonInfo = (state) => {
  let sitesObj = {};
  if (state.sites.status === 'success') {
    sitesObj = siteLatLongInfoObj(state.sites.sites);
  }

  return sitesObj;
};

export const selectStatesList = (state) => {
  let states = [];
  if (state.sites.status === 'success') {
    const myStates = new Set();
    state.sites.sites.forEach(s => {
      myStates.add(s.State)
    });

    if (myStates.size > 0) {
      states = Array.from(myStates);
    }
  }

  return states;
};

export const selectWaterBalance = (state) => state.sites.waterBalance;
export const getSitesStatus = (state) => state.sites.status;
export const getSitesError = (state) => state.sites.error;

export default sitesSlice.reducer;
