import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";


const SiteList = (props) => {
  const [sitesArray, setSitesArray] = useState([]);
  const [checked, setChecked] = useState([0]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    // console.log('SiteList', props.Sites);

    // Columnise the crops.
    const maxRows = Math.ceil(props.Sites.length / props.Columns);
    // console.log('Max Rows', maxRows);
    const sortedSites = props.Sites.toSorted();

    // Setup multidimensional array of sites. This is controlled
    // by the passed Columns value.
    let siteArray = [];
    for(let r = 0; r < maxRows; r++) {
      const thisRow = [];
      for(let c = 0; c < props.Columns; c++) {
        const siteIndex = (c * maxRows) + r;
        if (siteIndex < sortedSites.length) {
          thisRow.push(sortedSites[siteIndex]);
        }
      }
      siteArray.push(thisRow);
    }
    setSitesArray(siteArray);

    const initialSelection = [props.CurrentSiteName];
    setSelected(initialSelection);

  }, [props.Sites, props.Columns]);

  useEffect(() => {
    props.SiteSelect(selected);
  }, [selected]);

  const handleToggle = (value) => {
    if (!props.MultiSelect) {
      // Single selection only.
      const newSelected = [value];
      setSelected(newSelected);
    } else {
      // Multiple selection is allowed,
      const currentIndex = selected.indexOf(value);
      const newSelected = [...selected];

      if (currentIndex < 0) {
        newSelected.push(value);
      } else {
        newSelected.splice(currentIndex, 1);
      }

      setSelected(newSelected);
    }
  };

  //region Setup table styles
  const sxContainer = {
    width: "max-content",
    height: "max_content",
    marginLeft: "auto",
    marginRight: "auto",
  };
  //endregion

  const isSelected = (siteName) => {
    return selected.indexOf(siteName) === -1 ? false : true;
  };

  const renderRow = (siteItemsArray, rowKey) => {
    const cellArray = siteItemsArray.map(siteItem => {
      return  renderCell(siteItem, rowKey);
    });

    return (
      <TableRow key={rowKey}>
        {cellArray}
      </TableRow>
    );
  };

  const renderCell = (siteItem, rowKey) => {
    const modSiteItem = siteItem.replace(' ', '_');
    const cellKey = rowKey + modSiteItem;
    let displayValue = siteItem;
    if (props.BracketDemo && siteItem === 'Demo') {
      displayValue = `(${siteItem})`;
    }

    return (
      <TableCell
        key={cellKey}
        align="left">
        <FormControlLabel
          control = {
            <Checkbox
              color="primary"
              checked={isSelected(siteItem)}
              onChange={() => handleToggle(siteItem)}
            />
          }
          label={displayValue}>
        </FormControlLabel>
      </TableCell>
    );
  };

  const borderStyle = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#000000",
    borderSpacing: 1,
  };
  if (props.HideBorder) {
    borderStyle.borderWidth = '0px';
  }

  return (
    <div>
      {!props.HideTitle && <Typography variant="h5">Location/Site List</Typography>}
      <TableContainer component={Paper} sx={{...sxContainer}}>
        <Table
          sx={{
            width: "60%",
            "&.MuiTable-root": {...borderStyle}
          }}
          size="small"
          aria-label="location list"
          stickyHeader
        >
          <TableHead>
            <TableRow key="header1" >
            </TableRow>
          </TableHead>
          <TableBody>
            {sitesArray.map((row, rowIndex) => {
              const rowKey = `clrow${rowIndex}`;
              return renderRow(row, rowKey);
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

SiteList.propTypes = {
  Sites: PropTypes.array.isRequired,
  Columns: PropTypes.number,
  SiteSelect: PropTypes.func,
  HideTitle: PropTypes.bool,
  HideBorder: PropTypes.bool,
  MultiSelect: PropTypes.bool,
  CurrentSiteName: PropTypes.string,
  BracketDemo: PropTypes.bool,
}


SiteList.defaultProps = {
  Columns: 2,
  SiteSelect: () => {
  },
  HideTitle: false,
  HideBorder: false,
  MultiSelect: false,
  CurrentSiteName: '',
  BracketDemo: false,
}

export default SiteList;
