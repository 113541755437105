import axiosClient from "./axiosClient";

class dataHelper {
    constructor() {
        this.data = {};
    }

    sitesFromAPI() {
        // we don't want to call the API every time this function is called
        // if we have cached data, let's return that in a promise
        // and pretend it's an API call that's been successful.
        if (this.data.hasOwnProperty('data')) {
            return new Promise((resolve, reject) => {
                resolve(this.data);
            });
        };
        var self = this;
        return axiosClient.get('/sites', {})
            .then(function(response) {
                // if we've called the api already, let's cache the results on this class
                self.data = response;
                return response;
            });
    }

    sitesByState(sites) {
        var res = {};
        sites.forEach((site) => {
            var state = site["State"];
            var name = site["Name"];
            if (!(state in res)) {
                res[state] = [name];
            } else {
                res[state].push(name);
            }
        });
        return res;
    }

    siteLatLongInfo(sites) {
        var res = [];
        sites.forEach((site) => {
            if (site.Lat !== 0 && site.Lon !== 0) {
                res.push({
                    site: site["Name"],
                    state: site["State"],
                    lat: site["Lat"],
                    lng: site["Lon"]
                })
            }
        });
        return res;
    }

    infoForSite(site, sitesArray=null) {
        if (sitesArray !== null) {
            if (Array.isArray(sitesArray)) {
                return sitesArray.find(item => item.Name === site);
            }
        } else {
            if (this.data.hasOwnProperty('sites')) {
                return this.data.sites.find(item => item.Name === site);
            }
        }

        return null;
    }
}

const siteDataHelper = new dataHelper();

export default siteDataHelper;
