import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, FormControlLabel, FormGroup, FormLabel, Checkbox } from '@mui/material';

class CheckboxGroup extends Component {

    render() {
        return (
            <FormControl fullWidth sx={this.props.controlStyling}>
                <FormGroup row={this.props.horizontal}>
                    { this.props.label && <FormLabel sx={this.props.labelStyling}>{this.props.label}</FormLabel>}
                    {
                        this.props.options.map((option, index) => {
                            let optionDesc = option;
                            if (typeof option === 'string') {
                                if (option.toLowerCase() === 'demo' && this.props.BracketDemo) {
                                    optionDesc = `(${option})`;
                                }
                            }

                            return (
                                <FormControlLabel
                                    key={option}
                                    checked={typeof this.props.isChecked !== 'undefined' ? this.props.isChecked(option) : null}
                                    disabled={typeof this.props.isDisabled !== 'undefined' ? this.props.isDisabled(option) : null}
                                    onChange={typeof this.props.onChange !== 'undefined' ? this.props.onChange : null}
                                    control={<Checkbox data-update-index={this.props.updateIndex} value={option} />}
                                    label={typeof this.props.optionLabels !== 'undefined' ? this.props.optionLabels[index] : optionDesc}
                                />
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        )
    }
}

CheckboxGroup.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    optionLabels: PropTypes.array,
    horizontal: PropTypes.bool,
    controlStyling: PropTypes.object,
    labelStyling: PropTypes.object,
    isChecked: PropTypes.func,
    isDisabled: PropTypes.func,
    onChange: PropTypes.func,
    updateIndex: PropTypes.string,
    autoSelectSingleEntry: PropTypes.bool,
    BracketDemo: PropTypes.bool,
}

CheckboxGroup.defaultProps = {
    autoSelectSingleEntry: true,
    BracketDemo: false,
}

export default CheckboxGroup;
