import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography, Box, Divider, Tooltip } from '@mui/material';
import componentStyle from './ResultIndicatorModal.module.scss';
import CheckboxList from "./CheckboxList";
import miscHelper from "../ra_Helpers/miscHelper";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
};

const seasonalCondList = miscHelper.seasonalClimateConditions();

const SeasonalCondModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSeasonalCondSelect = (cond) => {
    // This now needs to be done in the parent component.
    // if (cond.length > 0) {
    //   dispatch(updateSeasonalConditions(cond));
    // }
    props.OnSeasonalConditionsChange(cond);
  };

  const convertToDisplay = () => {
    let displayVals = [];

    if (props.SelectedConditions) {
      if (props.SelectedConditions.length > 0) {
        props.SelectedConditions.forEach(c => {
          const conditionObj = seasonalCondList.find(s => s.value === c);
          if (conditionObj) {
            displayVals.push(conditionObj.display);
          }
        });
      }
    }

    if (displayVals.length === 0) {
      return "None Selected";
    } else {
      return displayVals.join(", ");
    }
  };

  const getButtonVariant = () => {
    let result = "outlined";

    if (props.SelectedConditions) {
      if (props.SelectedConditions.length > 0) {
        result = "contained";
      }
    }

    return result;
  };

  const displayConditions = convertToDisplay();

  return (
    <div className={componentStyle.container}>
      <div className={componentStyle.buttonContainer}>
        <div className={componentStyle.buttonHeading} >
          {props.Label}
        </div>
        <Tooltip title="Click to change the selected seasonal conditions">
          <Button
            sx={{ minWidth: '180px',maxHeight: '32px'}}
            variant={getButtonVariant()}
            disabled={props.Disabled}
            onClick={() => {setShowModal(true)}}
          >
            {displayConditions}
          </Button>
        </Tooltip>
      </div>

      <Modal
        open={showModal}
        onClose={() => {setShowModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className={componentStyle.header}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {props.Label}
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2}}>
              Select seasonal conditions (max of 2).
            </Typography>
            <Divider sx={{margin: "1em 0"}}/>
          </div>
          <div className={componentStyle.detail}>
            <CheckboxList
              Options={seasonalCondList}
              Columns={1}
              Title=""
              MaxSelectCount={2}
              TitleBackground="#dfdfdf"
              Selected={props.SelectedConditions}
              OnSelect={handleSeasonalCondSelect} />
          </div>
          <div className={componentStyle.footer}>
            <Box sx={{flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              <Button
                variant={"contained"}
                onClick={() => closeModal()}
              >
                Close
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

SeasonalCondModal.propTypes = {
  SelectedConditions: PropTypes.array,
  Label: PropTypes.string,
  Disabled: PropTypes.bool,
  OnSeasonalConditionsChange: PropTypes.func,
};

SeasonalCondModal.defaultProps = {
  Label: 'Seasonal Conditions:',
  Disabled: false,
  OnSeasonalConditionsChange: () => {},
};

export default SeasonalCondModal;
