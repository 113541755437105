import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {makeAColour, capitalise, ExploreListRowType, anycaseEquals} from "../functions";
import styles from './RefRotation.module.scss';
import {Checkbox, FormControlLabel, Tooltip} from "@mui/material";

//region Setup different table styles

const sxCommon = {
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
};

const sxCommonBorder = {
  paddingRight: 2,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderRightWidth: 1,
  borderRightStyle: "solid",
  borderRightColor: "#000000",
};

const sxContainer = {
  // width: "max-content",
  width: 'auto',
  minHeight: '180px',
  marginLeft: "auto",
  marginRight: "auto",
  padding: '2px',
};
//endregion

const CompareRotationList = (props) => {

  const renderCrop = (cropName, rowIndex, colIndex, withBorder) => {
    const cellKey = `r_${rowIndex}c${cropName}_${colIndex}`;

    let cropObj = null;
    if (props.Crops) {
      cropObj = props.Crops.find(c => anycaseEquals(c.Name, cropName));
    }

    const cellStyle = {};
    if (cropObj) {
      cellStyle.backgroundColor = makeAColour(cropObj.bg_colour);
      cellStyle.color = makeAColour(cropObj.fg_colour);
      cellStyle.padding = "0px 3px";
      cellStyle.margin = 0;

      if (withBorder) {
        cellStyle.borderRightWidth = 1;
        cellStyle.borderRightStyle = "solid";
        cellStyle.borderRightColor = "#000000";
      }

      return (
        <TableCell key = {cellKey} align="center" style={cellStyle}>
          {cropName.toLowerCase().trim()}
        </TableCell>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.compareContainer} >
      {props.Rotations &&
        <TableContainer component={Paper} sx={{...sxContainer}}>
          <fieldset style={{border: 'none'}}>
            <Table style={{ border: "1px solid grey" }} size="small" >
              <TableHead>
                <TableRow key="header1" >
                  <TableCell colSpan="1" align="center"></TableCell>
                  <TableCell colSpan="1" align="center"></TableCell>
                  <TableCell colSpan="2" align="center">Year 1</TableCell>
                  <TableCell colSpan="2" align="center">Year 2</TableCell>
                  <TableCell colSpan="2" align="center">Year 3</TableCell>
                  <TableCell colSpan="2" align="center">Year 4</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}></TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Nitrogen</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Targeted</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Summer/winter</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Planned time</TableCell>
                </TableRow>
                <TableRow sx={{maxHeight: 15}} key="header2" >
                  <TableCell colSpan="1" align="center" sx={{...sxCommonBorder}}>#</TableCell>
                  <TableCell colSpan="1" align="center" sx={{...sxCommonBorder}}>System</TableCell>
                  <TableCell align="center">Summer</TableCell>
                  <TableCell align="center">Winter</TableCell>
                  <TableCell align="center">Summer</TableCell>
                  <TableCell align="center">Winter</TableCell>
                  <TableCell align="center">Summer</TableCell>
                  <TableCell align="center">Winter</TableCell>
                  <TableCell align="center">Summer</TableCell>
                  <TableCell align="center">Winter</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Sowing Rule</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>Fertiliser Rate</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>crops/year</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>crop ratio</TableCell>
                  <TableCell align="center" sx={{...sxCommonBorder}}>in fallow</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.Rotations.map((row, index) => {
                  const rowKey = `row${row.RotationId}`;

                  return (
                    <TableRow key={rowKey} hover >
                      <TableCell padding="checkbox" style={{width: '24px'}} key={`r_${rowKey}_col`}>
                        <div style={{width: '24px'}}>{row.RotationId}</div>
                      </TableCell>
                      <TableCell key={`r_${rowKey}_sys`} align="center" sx={{...sxCommon}}>{row.Type}</TableCell>
                      {renderCrop(row.Yr1_Summer, index, 1, 0)}
                      {renderCrop(row.Yr1_Winter, index, 2, 1)}
                      {renderCrop(row.Yr2_Summer, index, 3, 0)}
                      {renderCrop(row.Yr2_Winter, index, 4, 1)}
                      {renderCrop(row.Yr3_Summer, index, 5, 0)}
                      {renderCrop(row.Yr3_Winter, index, 6, 1)}
                      {renderCrop(row.Yr4_Summer, index, 7, 0)}
                      {renderCrop(row.Yr4_Winter, index, 8, 1)}
                      <TableCell key={rowKey + "sr"} align="left" sx={{...sxCommonBorder}} className={styles.cellEllipsis}>
                        <Tooltip title={row.SowingRuleDescription}>
                          <div className={styles.trimText}>{row.SowingRuleDescription}</div>
                        </Tooltip>
                      </TableCell>
                      <TableCell key={rowKey + "nf"} align="center" sx={{...sxCommonBorder}}>{capitalise(row.NLevel)}</TableCell>
                      <TableCell key={rowKey + "tc"} align="center" sx={{...sxCommonBorder}}>{row.TargetedCrops}</TableCell>
                      <TableCell key={rowKey + "cr"} align="center" sx={{...sxCommonBorder}}>{row.CropRatio}</TableCell>
                      <TableCell key={rowKey + "tf"} align="center" sx={{...sxCommon}}>{row.PlannedFallow}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </fieldset>
        </TableContainer>}
    </div>
  );
}

CompareRotationList.propTypes = {
  Rotations: PropTypes.array.isRequired,
  Crops: PropTypes.array.isRequired,
};

export default CompareRotationList;
