import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Button, Stepper, Step, StepLabel} from '@mui/material';
import styles from './RotationStepper.module.scss';
import TextList from "../ra_components/TextList";
import PriceStep from "./PriceStep";
import ManagementStep from "./ManagementStep";
import {
  selectBaseRotation,
  selectNitrogenLevel,
  selectSowingRuleId,
  selectSeasonalConditions,
  selectResultIndicator
} from "../features/rotationSlice";
import {
  updateSite,
  updatePAWC,
  selectSiteDetail,
  selectPAWC, selectSite
} from '../features/sitesSlice';
import RefRotationStep from "./RefRotationStep";
import ResultStep from "./ResultStep";
import SiteStep from "./SiteStep";
import LoadingComponent from "../ra_components/LoadingComponent";

// const steps = ["Site & Soil", "Prices", "Reference Rotation", "Management", "Reference Results", "Explore Rotations"];
const steps = ["Site & Soil", "Prices", "Reference Rotation", "Management", "Reference Results"];

const RotationStepper = (props) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [scenarios, setScenarios] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const baseRotationId = useSelector(selectBaseRotation);

  const resultsIndicator = useSelector(selectResultIndicator);
  const seasonalConditions = useSelector(state => selectSeasonalConditions(state));

  const currentPAWC = useSelector(state => selectPAWC(state));
  const currentSite = useSelector(selectSite);
  const currentSiteDetail = useSelector(selectSiteDetail, { devModeChecks: { stabilityCheck: "never" }});
  const currentSiteName = currentSiteDetail.name || null;
  const currentSowingRule = useSelector(selectSowingRuleId);
  const currentNLevel = useSelector(selectNitrogenLevel);

  const currentStepName = useMemo(() => {
    const stepName =  steps[currentStep];
    return stepName;
  }, [currentStep]);

  //region Handle stepper progress buttons

  const handleNext = (event) => {
    event.preventDefault();

    if (currentStepName === 'Reference Results') {
      props.OnSubmit();
    } else {
      if (currentStepName === 'Site & Soil') {
      }
      let newStep = currentStep + 1;
      if (newStep >= steps.length) {
        newStep = steps.length - 1;
      }
      if (newStep !== currentStep) {
        setCurrentStep(newStep);
      }
    }
  };

  const handlePrevious = (event) => {
    let newStep = currentStep - 1;
    if (newStep < 0) {
      newStep = 0;
    }
    if (newStep !== currentStep) {
      setCurrentStep(newStep);
    }
  };

  const nextDisabled = () => {
    let disabled = false;

    if (currentStepName.toLowerCase() === 'site & soil') {

      if (currentSite === null || currentSite === '') {
        disabled = true;
      }
      if (currentPAWC === null || currentPAWC === 0) {
        disabled = true;
      }

      // TODO Analytics
      // Advise GA of the site selected.
      if (currentSiteName) {

      }
    }

    if (currentStepName.toLowerCase() === 'prices') {
      disabled = false;
    }

    if (currentStepName.toLowerCase() === 'reference rotation') {
      if (!baseRotationId) {
        disabled = true;
      }
    }

    if (currentStepName.toLowerCase() === 'management') {
      if (currentSowingRule === null || currentSowingRule === '') {
        disabled = true;
      }
      if (currentNLevel === null || currentNLevel === '') {
        disabled = true;
      }
    }

    // Result selection.
    if (currentStepName.toLowerCase() === 'reference results') {
      if (resultsIndicator === '' || seasonalConditions === '') {
        disabled = true;
      }
    }

    return disabled;
  };
  //endregion

  //region Handle user selections
  const handleSiteSelection = (site, value) => {
    // Receives: Site object, true/false (which is ignored)
    if (site) {
      if (currentSite !== site.Name) {
        dispatch(updateSite(site.id));
      }
    }
  };

  const handleSoilPAWCSelect = (selectedPAWC) => {
    if (selectedPAWC.length > 0) {
      const pawcVal = Number(selectedPAWC[0]);
      dispatch(updatePAWC(pawcVal));
    }
  };

  const handleScenarioAddition = (site, crop, selectedSimFactors) => {
    let scenariosToAdd;

    // let's make sure we take into account the overarching soil choices for this site
    if (this.state.soilSettings[site].soil_type) {
      scenariosToAdd = [
        {
          Crop: crop,
          Site: site,
          ScenarioType: 'normal',
        }
      ];
    } else {
      scenariosToAdd = [
        {
          Crop: crop,
          Site: site,
          PAWC: this.state.soilSettings[site].pawc,
          ScenarioType: 'normal',
        }
      ];
    }

    // We need to calculate all the possible variations of scenarios to be added to our state.
    Object.keys(selectedSimFactors).forEach((factor) => {
      let updatedScenarios = [];
      let simValues = selectedSimFactors[factor];
      simValues.forEach((value) => {
        scenariosToAdd.forEach((scenario) => {
          let scenarioObj = {...scenario};
          scenarioObj[factor] = value;
          updatedScenarios.push(scenarioObj);
        })
      })
      scenariosToAdd = updatedScenarios;
    });

    let scenarioList = [
      ...scenarios,
      ...scenariosToAdd
    ];

    setScenarios(scenarioList);
  };
  //endregion

  const areStepConditionsMet = (step) => {
    return true;
  };

  const getStepContent = (step) => {
    if (isLoading) {
      return (
        <LoadingComponent State='loading' />
      );
    }

    switch(currentStepName) {
      case 'Site & Soil':
        return (
          <div>
            <SiteStep />
          </div>
        );
      case 'Prices':
        return (
          <div>
            <PriceStep SiteId={currentSite} SiteName={currentSiteName} />
          </div>
        );
      case 'Reference Rotation':
        return (
          <div>
            <RefRotationStep />
          </div>
        );
      case 'Management':
        return (
          <div>
            <ManagementStep
              CurrentSowingRule={currentSowingRule.toString()}
              CurrentNitrogenLevel={currentNLevel} />
          </div>
        );
      case 'Reference Results':
        return (
          <div>
            <ResultStep ResultIndicator={resultsIndicator} SeasonalConditions={seasonalConditions} />
          </div>
        );
    }
  };

  const isLastStep = () => {
    let result = false;
    // Checks we are on the last step and all data is valid.
    if (currentStep === steps.length - 1) {
      result = true;
    }

    return result;
  };

  let forwardButtonName = 'Next';
  if (currentStepName === 'Reference Results') {
    forwardButtonName = 'Submit';
  }
  if (currentStepName === 'Explore Rotations') {
    forwardButtonName = 'Submit';
  }

  return (
    <>
      <div className={styles.stepContainer}>
        <Stepper activeStep={currentStep}>
          {steps.map((value) => {
            return (
              <Step
                hidden={!areStepConditionsMet(value)}
                key={value}
                completed={false}>
                <StepLabel>{value}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>

      <div className={styles.middleContainer}>
        {getStepContent(currentStep)}
      </div>
      <div className={styles.lowerContainer}>
        <div>
          {dataErrors.length > 0 && <TextList Items={dataErrors} AsErrors={true} ListTitle="Errors (correct befor proceeding)" />}
        </div>
        <div className={styles.buttonContainer}>
          {currentStep !== 0 && <Button onClick={() => handlePrevious()}>Previous</Button>}
          <Button
            disabled={nextDisabled()}
            onClick={(event) => handleNext(event)}>
            {forwardButtonName}
          </Button>
        </div>
      </div>
    </>
  );

};

RotationStepper.propTypes = {
  OnSubmit: PropTypes.func,
};

RotationStepper.defaultProps = {
  OnSubmit: () => {},
};

export default RotationStepper;
