import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import parallelCoordinates from 'highcharts/modules/parallel-coordinates';

import InfoButton from "./InfoButton";

import styles from './sharedPageStyles.module.scss';
import miscHelper from "../ra_Helpers/miscHelper";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
highchartsAccessibility(Highcharts);
parallelCoordinates(Highcharts);

const ROTATION = {
  BEST: 0,
  REFERENCE: 1,
  WORST: 2,
};

const INDICATORS = miscHelper.sectionCIndicators();

const BestWorstChart = (props) => {
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);
  const chartRenderDone = useRef(false);

  const parallelIndicators = useMemo(() => {
    const filteredIndicators = INDICATORS.filter(r => r.spider);
    filteredIndicators.sort((a, b) => a.spiderSort - b.spiderSort);

    return filteredIndicators;
  }, [INDICATORS]);

  const referenceId = props.Rotations[ROTATION.REFERENCE]?.BaseRotationId;
  const bestId = props.Rotations[ROTATION.BEST]?.BaseRotationId;
  const worstId = props.Rotations[ROTATION.WORST]?.BaseRotationId;

  const bestDescription = (indicator) => {
    let result = '';
    if (indicator) {
      let position = indicator.axisReverse ? 'Lowest' : 'Highest';
      const desc = indicator.display.toLowerCase();
      result = `${position} ${desc} rotation`;
    }

    return result;

  };

  const worstDescription = (indicator) => {
    let result = '';
    if (indicator) {
      let position = indicator.axisReverse ? 'Highest' : 'Lowest';
      const desc = indicator.display.toLowerCase();
      result = `${position} ${desc} rotation`;
    }

    return result;

  };

  useEffect(() => {
    const chartOpt = {
      chart: {
        type: 'spline',
        parallelCoordinates: true,
        parallelAxes: {
          labels: {
            style: {
              color: '#999999',
            }
          },
          gridLineWidth: 0,
          lineWidth: 2,
          showFirstLabel: true,
          showLastLabel: true
        },
        events: {
          render: function() {
            const chart = this;
            if (!chart.hasOwnProperty('customText')) {
              chart.customText = chart.renderer.text(chart_note)
                .css({
                  color: '#303030',
                  fontSize: '12px',
                  fontStyle: 'italic',
                })
                .add();

              chartRenderDone.current = true;
            }
          },
          redraw: function() {
            const chart = this;
            if (!chart.hasOwnProperty('customText')) {
              // Nothing to do if object doesn't exist yet.
            } else {
              const legendAttr = chart.legend.box.getBBox();
              const legendBox = chart.legend.box;
              const chartLegendX = legendBox.parentGroup.alignAttr.translateX;
              const chartLegendY = legendBox.parentGroup.alignAttr.translateY;

              const textX = chart.plotWidth / 2;
              const textY = chartLegendY + legendAttr.height + 5;
              chart.customText.attr({
                text: chart_note,
                align: 'center',
                x: textX, // offset
                verticalAlign: 'bottom',
                y: textY // offset
              });
            }
          }
        },
      },
      title: {
        text: getTitle(),
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span>' +
          '{series.name}: <b>{point.formattedValue}</b><br/>'
      },
      plotOptions: {
        series: {
          accessibility: {
            enabled: false
          },
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          states: {
            hover: {
              halo: {
                size: 0
              }
            }
          },
          events: {
            mouseOver: function () {
              this.group.toFront();
            },
          }
        }
      },
      xAxis: {
        categories: makeIndicatorCategories(),
        offset: 10,
      },
      yAxis: configureYAxis(),
      colors: ['rgba(11, 200, 200, 0.1)'],
      series: getChartData(),
    }

    setChartOptions(chartOpt);

  }, [props.Rotations, props.IndicatorObj]);

  const getTitle = () => {
    if (props.Title === '') {
      return 'Multi-criteria performance';
    } else {
      return props.Title;
    }
  };

  const configureYAxis = () => {
    let yaxis = [];

    parallelIndicators.forEach(ind => {
      const indicator = ind.value;


      let tooltipFormat = '{value}';
      if (ind.units !== '') {
        tooltipFormat = `{value} (${ind.units})`;
      }

      const axisConfig = {
        name: indicator,
        labels: {
          format: '{value}',
          align: 'right',
          x: -5,
        },
        ticks: 8,
        tickWidth: 1,
        tickLength: 3,
        tooltipValueFormat: tooltipFormat,
        reversed: ind.axisReverse,
      }

      if (ind.hasOwnProperty('minAxisValue')) {
        axisConfig.min = ind.minAxisValue;
      }

      yaxis.push(axisConfig);
    });

    return yaxis;
  };

  const makeIndicatorCategories = () =>  {
    const categories = [];

    parallelIndicators.forEach(indicator => {
      let catDisplay = indicator.display;
      if (indicator.units !== '') {
        catDisplay += ' (' + indicator.units + ')';
      }
      categories.push(catDisplay);
    });

    return categories;
  }

  const makeSeriesArray = (rotationObj, seriesName) => {
    let ary = [];

    if (rotationObj) {
      parallelIndicators.forEach(ind => {
        const indicator = ind.value;
        if (rotationObj[indicator] !== null) {
          const roundedValue = Math.round(rotationObj[indicator] * 1000) / 1000;
          ary.push(roundedValue);
        } else {
          ary.push(null);
        }
      });
    }

    return ary;
  };

  const getChartData = () => {
    const series = [];

    if (props.Rotations?.length > 0) {

      const dataSeriesObj = {
        name: 'Reference rotation',
        color: 'blue',
        data: [...makeSeriesArray(props.Rotations[ROTATION.REFERENCE])],
        shadow: false,
      };
      series.push(dataSeriesObj);

      // Show best rotation if not same as reference
      if (bestId !== referenceId) {
        const dataSeriesObj = {
          name: bestDescription(props.IndicatorObj),
          color: 'green',
          data: [...makeSeriesArray(props.Rotations[ROTATION.BEST])],
          shadow: false,
        };
        series.push(dataSeriesObj);
      }

      // Show worst rotation if not same as reference
      if (worstId !== referenceId) {
        const dataSeriesObj = {
          name: worstDescription(props.IndicatorObj),
          color: 'red',
          data: [...makeSeriesArray(props.Rotations[ROTATION.WORST])],
          shadow: false,
        };
        series.push(dataSeriesObj);
      }

    }

    // console.log('BestWorst data', series);

    return series;
  };

  let chart_note = '';
  if (referenceId === worstId) {
    chart_note += `Reference rotation = ${worstDescription}`;
  }
  if (referenceId === bestId) {
    if (chart_note !== '') {
      chart_note += ', ';
    }
    chart_note += `Reference rotation = ${bestDescription}`;
  }

  return (
    <div className={styles.graphContainer}>
      <div>
        {props.Rotations && <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={chartOptions}
          constructorType={'chart'}
        />}
      </div>
      <div className={styles.chartExplanation}>
        {props.Explanation}
      </div>
    </div>
  );
}

BestWorstChart.propTypes = {
  Rotations: PropTypes.array.isRequired,
  IndicatorObj: PropTypes.object.isRequired,
  Title: PropTypes.string,
  Explanation: PropTypes.string,
  InfoKey: PropTypes.string,
};

BestWorstChart.defaultProps = {
  Title: '',
  Explanation: '',
  InfoKey: '',
};
export default BestWorstChart;
